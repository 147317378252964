import {
  context$,
  groups$,
  includeSubsidiaries$,
  language$,
  layouts$,
  loading$,
  locale$,
  notifications$,
  organisations$,
  pageSize$,
  reducedContext$,
  reportingPeriods$,
  reportingStandards$,
  rules$,
  supportedLanguage$,
  userContextQuery,
  userId$,
} from 'context/UserContext/query';
import store from 'context/UserContext/store';
import OrganisationsService from 'state/Organisations/service';
import UsersService from 'state/User/service';

const api = new UsersService();
const organisationsAPI = new OrganisationsService();

const facade = {
   api,
   organisationsAPI,
   store,
   query: userContextQuery,
   context$,
   language$,
   notifications$,
   organisations$,
   reportingStandards$,
   reportingPeriods$,
   includeSubsidiaries$,
   pageSize$,
   loading$,
   locale$,
   userId$,
   groups$,
   rules$,
   reducedContext$,
   layouts$,
   supportedLanguage$,
};

export default facade;
