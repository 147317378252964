import {
  activeOrganisationId$,
  characteristics$,
  consolidationMethod$,
  currency$,
  currencyCode$,
  customization$,
  emissionUnit$,
  organisationContextQuery,
  organisationId$,
  parentIds$,
  reducedContext$,
  reportingStandards$,
  results$,
  subsidiaries$,
  subsidiaryIds$,
  theme$,
  type$,
} from 'context/OrganisationContext/query';
import { loading$ } from 'context/UserContext/query';
import UsersService from 'state/User/service';

const api = new UsersService();

const facade = {
   api,
   query: organisationContextQuery,
   loading$,
   reducedContext$,
   subsidiaryIds$,
   parentIds$,
   activeOrganisationId$,
   organisationId$,
   reportingStandards$,
   currencyCode$,
   type$,
   subsidiaries$,
   characteristics$,
   emissionUnit$,
   results$,
   consolidationMethod$,
   currency$,
   customization$,
   theme$,
};

export default facade;
