import { arrayUpdate, combineQueries, filterNilValue } from '@datorama/akita';
import { activeOrganisationId$, organisationContextQuery } from 'context/OrganisationContext/query';
import { context$ as periodContext$, periodContextQuery } from 'context/PeriodContext/query';
import { includeSubsidiaries$ } from 'context/UserContext/query';
import userContextStore from 'context/UserContext/store';
import { formatISO } from 'date-fns';
import { isEqual, without } from 'lodash-es';
import { distinctUntilChanged, distinctUntilKeyChanged, map, of, tap } from 'rxjs';
import CRUDService from 'state/CRUDService';
import KPISetService from 'state/KPISet/service';
import KPISubsService from 'state/KPISub/service';
import MinimumSafeguardsService from 'state/MinimumSafeguards/service';
import { query as reportingStandardsQuery } from 'state/ReportingStandards/query';
import { query as uiQuery } from 'state/UI/query';
import { activeEntity$ as activeUIPeriod$ } from 'state/UIPeriod/query';
import UsersService from 'state/User/service';
import { query } from './query';
import store from './store';

export default class OrganisationsService extends CRUDService {
   constructor() {
      if (!OrganisationsService.instance) {
         super('organisations', store, query, [], false, true, false, false, false);
         this.statisticsQueryParamsObservable = this.getStatisticsQueryParams();
         OrganisationsService.instance = this;
         this.usersService = new UsersService();
         this.userContextStore = userContextStore;
         this.organisationContextQuery = organisationContextQuery;
         this.periodContextQuery = periodContextQuery;
         this.kpiSetService = new KPISetService();
         this.kpiSubsService = new KPISubsService();
         this.minimumSafeguardsService = new MinimumSafeguardsService();
         this.emissionResultObservable = undefined;
      }

      // Service shall be instantiated only once, because otherwise the observable will be created for each service instance

      return OrganisationsService.instance;
   }

   getEmissionResults() {
      if (!this.emissionResultObservable || this.emissionResultObservable?.closed) {
         this.emissionResultObservable = combineQueries([
            activeOrganisationId$.pipe(filterNilValue(), distinctUntilChanged()),
            periodContext$.pipe(filterNilValue(), distinctUntilKeyChanged('id')),
            includeSubsidiaries$.pipe(filterNilValue(), distinctUntilChanged()),
         ])
            .pipe(
               distinctUntilChanged(isEqual),
               tap(([organisationId, { from, to }, includeSubsidiaries]) =>
                  this.updateOrganisationResults(organisationId, from, to, includeSubsidiaries)
               )
            )
            .subscribe();
      }
   }

   async updateOrganisationContextIfModified(entityId) {
      const organisationId = organisationContextQuery.getValue()?.organisation?.id;
      const activeOrganisationId = organisationContextQuery.getValue()?.activeOrganisation?.id;
      if ([organisationId, activeOrganisationId].includes(entityId)) {
         this.usersService.getLoggedInUser();
      }
   }

   async getEntities(force = false, store = this.store, url = null) {
      const urlParams = new URLSearchParams(this.customQueryParams);
      urlParams.set('deleted', uiQuery.getValue().showDeletedOrg);

      this.customQueryParams = urlParams;
      return super.getEntities(force, store, url);
   }

   async updateEntity(entityId, changes) {
      const result = await super.updateEntity(entityId, changes);
      this.updateOrganisationContextIfModified(entityId);
      return result;
   }

   async createEntity(entity, fetchAssociations = false) {
      const result = await super.createEntity(entity, fetchAssociations);
      this.usersService.getLoggedInUser();
      return result;
   }

   async deleteEntity(entityIdOrArray) {
      const entity = super.getEntityState(entityIdOrArray);

      if (entity?.deleteOperationInProgress) {
         return undefined;
      }

      this.store.update(entity.id, { deleteOperationInProgress: true, deleteOperationStatus: 'PENDING' });

      return super
         .deleteEntity(entityIdOrArray)
         .then(() =>
            this.store.upsert(entity.id, {
               deletedAt: new Date().toISOString(),
               deleteOperationInProgress: false,
               deleteOperationStatus: 'SUCCESS',
               name: entity.name,
               code: entity.code,
            })
         )
         .catch(() =>
            this.store.upsert(entity.id, { deleteOperationInProgress: false, deleteOperationStatus: 'ERROR', name: entity.name, code: entity.code })
         );
   }

   #updatePropertyInCurrentOrganisation(propertyName, value) {
      return this.userContextStore.update((state) => ({
         ...state,
         activeOrganisation: {
            ...state.activeOrganisation,
            [propertyName]: value,
         },
      }));
   }

   async updateOrganisationResults(organisationId, from, to, includeSubsidiaries) {
      const queryParams = new URLSearchParams();

      if (organisationId) {
         queryParams.set('organisationId', organisationId);
      }
      if (from) {
         queryParams.set('from', from);
      }
      if (to) {
         queryParams.set('to', to);
      }
      if (includeSubsidiaries) {
         queryParams.set('includeSubsidiaries', includeSubsidiaries);
      }

      if (organisationId) {
         return this.httpClient
            .get(`/${this.version}/${this.entityName}/${organisationId}/results?${queryParams.toString()}`)
            .then((resp) => this.#updatePropertyInCurrentOrganisation('results', resp.data))
            .catch((error) => this.setError(error));
      }

      return false;
   }

   async addReportingStandards(reportingStandardIdArray) {
      const context = organisationContextQuery.getValue();

      if (context?.activeOrganisation?.id) {
         return this.httpClient
            .post(`/${this.version}/${this.entityName}/${context?.activeOrganisation?.id}/reportingstandards`, {
               reportingStandards: reportingStandardIdArray,
            })
            .then((resp) => {
               this.#updatePropertyInCurrentOrganisation('reportingStandards', resp.data);
               return this.kpiSetService.getEntities(true);
            })
            .catch((error) => this.setError(error));
      }

      return false;
   }

   async changeReportingStandardAssignment(reportingStandardId, changes) {
      const context = organisationContextQuery.getValue();

      if (context?.activeOrganisation?.id) {
         return this.httpClient
            .patch(`/${this.version}/${this.entityName}/${context?.activeOrganisation?.id}/reportingstandards/${reportingStandardId}`, changes)
            .then((resp) => {
               this.userContextStore.update((state) => ({
                  ...state,
                  activeOrganisation: {
                     ...state.activeOrganisation,
                     reportingStandards: arrayUpdate(state.activeOrganisation.reportingStandards, reportingStandardId, resp.data),
                  },
               }));

               return this.kpiSetService.getEntities(true);
            })
            .catch((error) => this.setError(error));
      }

      return false;
   }

   getStatisticsQueryParams() {
      const dependencies = [];

      dependencies.push(activeOrganisationId$.pipe(filterNilValue()));
      dependencies.push(activeUIPeriod$.pipe(filterNilValue()));
      dependencies.push(includeSubsidiaries$.pipe(filterNilValue()));
      dependencies.push(uiQuery.select('statisticsFilters').pipe(filterNilValue()));

      if (dependencies.length) {
         return combineQueries(dependencies).pipe(
            distinctUntilChanged(isEqual),
            map((values) => {
               const urlParamsSubClass = new URLSearchParams();

               if (this.useScope === true) {
                  urlParamsSubClass.set('scope', true);
               } else if (this.useScope === false) {
                  urlParamsSubClass.set('scope', false);
               } else {
                  urlParamsSubClass.set('scope', 'any');
               }

               if (values.length === dependencies.length) {
                  const uiPeriod = values.find((value) => value && Object.hasOwn(value, 'from') && Object.hasOwn(value, 'to'));
                  const includeSubsidiaries = values.find((value) => typeof value === 'boolean');
                  const organisationId = values.find((value) => typeof value === 'number');

                  if (organisationId) {
                     urlParamsSubClass.set('organisationId', organisationId);
                  }

                  if (uiPeriod) {
                     urlParamsSubClass.set('from', formatISO(uiPeriod.from, { representation: 'date' }));
                     urlParamsSubClass.set('to', formatISO(uiPeriod.to, { representation: 'date' }));
                  }

                  if (includeSubsidiaries) {
                     urlParamsSubClass.set('includeSubsidiaries', includeSubsidiaries);
                  }

                  const filters = values.find(
                     (value) =>
                        typeof value === 'object' &&
                        Object.keys(value).every((key) => ['periods', 'reportingStandards', 'organisations', 'tags'].includes(key))
                  );
                  if (filters) {
                     const groupedFilter = Object.entries(filters).reduce((acc, [kind, filterItems]) => {
                        const formatData = (date) => formatISO(typeof date === 'string' ? new Date(date) : date, { representation: 'date' });
                        switch (kind) {
                           case 'periods':
                              return {
                                 ...acc,
                                 from: filterItems.map((filter) => formatData(filter?.from)),
                                 to: filterItems.map((filter) => formatData(filter?.to)),
                              };
                           case 'reportingStandards':
                              return {
                                 ...acc,
                                 reportingStandardId: filterItems.map((filter) => filter?.id),
                              };
                           case 'organisations':
                              return {
                                 ...acc,
                                 organisationId: filterItems.map((filter) => filter?.id),
                              };
                           case 'tags':
                              return {
                                 ...acc,
                                 tag: filterItems.map((filter) => filter?.name),
                              };
                           default:
                              return acc;
                        }
                     }, {});

                     for (const [key, value] of Object.entries(groupedFilter)) {
                        if (value.length) {
                           urlParamsSubClass.set(key, value.join(','));
                        }
                     }
                  }

                  if (uiPeriod && organisationId) {
                     return `?${urlParamsSubClass.toString()}`;
                  }
               }

               return false;
            })
         );
      }

      const urlParams = new URLSearchParams();

      if (this.useScope === true) {
         urlParams.set('scope', true);
      } else if (this.useScope === false) {
         urlParams.set('scope', false);
      } else {
         urlParams.set('scope', 'any');
      }
      return of(`?${urlParams.toString()}`);
   }

   async getStatistics() {
      if (this.getStatisticsObservable === undefined || this.getStatisticsObservable?.closed) {
         this.getStatisticsObservable = this.statisticsQueryParamsObservable
            .pipe(filterNilValue(), distinctUntilChanged())
            .subscribe((queryString) => {
               if (typeof queryString === 'string') {
                  if (this.statisticsController) {
                     this.statisticsController.abort();
                  }

                  this.statisticsController = new AbortController();

                  this.store.setLoading(true);
                  this.httpClient
                     .get(`/${this.version}/statistics/emissionsinfo${queryString}`, {
                        signal: this.statisticsController.signal,
                     })
                     .then((resp) => {
                        this.store.update({ statistics: resp.data });
                        return this.store.setLoading(false);
                     })
                     .catch((error) => {
                        this.setError(error);
                     });
               }

               return undefined;
            });
      }
   }

   async removeReportingStandard(reportingStandardId) {
      let reportingStandardIds;

      const context = organisationContextQuery.getValue();
      const periodContext = periodContextQuery.getValue();
      reportingStandardIds = context.activeOrganisation.reportingStandards.map((rs) => rs.id);

      if (reportingStandardIds.length === 0) {
         const allReportingStandards = reportingStandardsQuery.getAll();
         reportingStandardIds = allReportingStandards.map((rs) => rs.id);
      }

      this.httpClient
         .put(`/${this.version}/${this.entityName}/${context?.activeOrganisation?.id}/reportingstandards`, {
            from: periodContext?.activePeriod?.from,
            to: periodContext?.activePeriod?.to,
            reportingStandards: without(reportingStandardIds, reportingStandardId),
         })
         .then((resp) => {
            this.#updatePropertyInCurrentOrganisation('reportingStandards', resp.data);
            return this.kpiSetService.getEntities(true);
         })
         .catch((error) => this.setError(error));
   }

   async setTags(organisationId, tags = []) {
      this.httpClient
         .put(`/${this.version}/${this.entityName}/${organisationId}/tags`, tags)
         .then((resp) => this.store.update(organisationId, { tags: resp.data }))
         .catch((error) => this.setError(error));
   }

   async setIndustries(organisationId, industries = []) {
      this.httpClient
         .put(`/${this.version}/${this.entityName}/${organisationId}/industries`, industries)
         .then((resp) => this.store.update(organisationId, { industries: resp.data }))
         .catch((error) => this.setError(error));
   }

   async setCharacteristics(organisationId, characteristics = []) {
      this.httpClient
         .put(`/${this.version}/${this.entityName}/${organisationId}/characteristics`, characteristics)
         .then((resp) => {
            this.store.update(organisationId, { characteristics: resp.data });
            this.minimumSafeguardsService.getStatus();
            return this.userContextStore.update((context) => ({
               ...context,
               activeOrganisation: {
                  ...context.activeOrganisation,
                  characteristics: resp.data,
               },
            }));
         })
         .catch((error) => this.setError(error));
   }

   async resetTheme(organisationId) {
      return this.httpClient
         .patch(`/${this.version}/${this.entityName}/${organisationId}`, {
            theme: null,
            logo: null,
            banner: null,
            background: null,
         })
         .then((resp) => {
            if (this.query.hasEntity(organisationId)) {
               this.store.update(organisationId, resp.data);
            }
            this.#updatePropertyInCurrentOrganisation('logo', null);
            this.#updatePropertyInCurrentOrganisation('banner', null);
            this.#updatePropertyInCurrentOrganisation('background', null);
            return this.#updatePropertyInCurrentOrganisation('theme', null);
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async #uploadFile(organisationId, files, propertyName) {
      files.forEach((file) => {
         const formData = new FormData();
         formData.append(propertyName, file);

         this.httpClient
            .post(`/${this.version}/${this.entityName}/${organisationId}/${propertyName}`, formData, { 'Content-Type': 'multipart/form-data' })
            .then((resp) => {
               if (this.query.hasEntity(organisationId)) {
                  this.store.update(organisationId, resp.data);
               }
               return this.#updatePropertyInCurrentOrganisation(propertyName, resp.data[propertyName]);
            })
            .catch((error) => {
               this.setError(error);
            });
      });
   }

   async uploadLogo(organisationId, files) {
      return this.#uploadFile(organisationId, files, 'logo');
   }

   async uploadBanner(organisationId, files) {
      return this.#uploadFile(organisationId, files, 'banner');
   }

   async uploadBackground(organisationId, files) {
      return this.#uploadFile(organisationId, files, 'background');
   }

   async uploadFavIcon(organisationId, files) {
      return this.#uploadFile(organisationId, files, 'favicon');
   }
   async restore(organisationId) {
      return this.httpClient
         .post(`/${this.version}/${this.entityName}/${organisationId}/restore`)
         .then((resp) => this.store.update(organisationId, resp.data))
         .catch((error) => this.setError(error));
   }

   async updateCache() {
      return this.httpClient
         .post(`/${this.version}/${this.entityName}/updatecache`)
         .then(() => this.getEntities(true))
         .catch((error) => this.setError(error));
   }

   setSortOrder(sort) {
      this.store.update({ sort });
   }

   unsubscribeStatistics() {
      if (this.getStatisticsObservable && !this.getStatisticsObservable.closed) {
         this.getStatisticsObservable.unsubscribe();
      }
      if (this.statisticsController) {
         this.statisticsController.abort();
      }
   }

   unsubscribeEmissionResults() {
      if (this.emissionResultObservable && !this.emissionResultObservable.closed) {
         this.emissionResultObservable.unsubscribe();
      }
   }
}
