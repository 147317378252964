import { combineQueries, createQuery, filterNilValue } from '@datorama/akita';
import userContextStore from 'context/UserContext/store';
import { flatMapDeep, isEqual, pick } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs';
import { entities$ as organisations$ } from 'state/Organisations/query';

function getParentIds(organisation, previousParentIds, organisations) {
   if (organisation?.parents?.length > 0) {
      return organisation.parents
         .map((parent) =>
            getParentIds(
               organisations.find(({ id }) => parent?.id === id || parent?.organisation_parent?.parentId === id),
               previousParentIds.concat(parent?.id ?? parent?.organisation_parent?.parentId),
               organisations
            )
         )
         .flat();
   }
   return previousParentIds.flat();
}

export const organisationContextQuery = createQuery(userContextStore);

export const context$ = organisationContextQuery.select('activeOrganisation');

export const activeOrganisationId$ = organisationContextQuery.select('activeOrganisationId');
export const organisationId$ = organisationContextQuery.select('organisationId');

export const reportingStandards$ = context$.pipe(
   map((org) => org?.reportingStandards ?? []),
   distinctUntilChanged(isEqual)
);

export const consolidationMethod$ = context$.pipe(
   map((org) => org?.consolidationMethod ?? 'FULL'),
   distinctUntilChanged(isEqual)
);

export const currencyCode$ = context$.pipe(
   map((org) => org?.currency?.shortCode),
   distinctUntilChanged()
);

export const currency$ = context$.pipe(
   map((org) => org?.currency),
   distinctUntilChanged()
);

export const type$ = context$.pipe(
   map((org) => org?.type ?? 'ORGANIZATION'),
   distinctUntilChanged()
);

export const emissionUnit$ = context$.pipe(
   map((org) => org?.emissionUnit ?? 'kg'),
   distinctUntilChanged()
);

export const subsidiaries$ = context$.pipe(
   map((org) => org?.subsidiaries ?? []),
   distinctUntilChanged(isEqual)
);

export const characteristics$ = context$.pipe(
   map((org) => org?.characteristics ?? []),
   distinctUntilChanged(isEqual)
);

export const results$ = context$.pipe(
   map((org) => org?.results ?? []),
   distinctUntilChanged(isEqual)
);

function flatten(item) {
   return [item, flatMapDeep(item.subsidiaries, flatten)];
}

export const subsidiaryIds$ = context$.pipe(
   filterNilValue(),
   map((activeOrganisation) => flatMapDeep([activeOrganisation], flatten).map((org) => org.id)),
   distinctUntilChanged(isEqual)
);

export const parentIds$ = combineQueries([context$, organisations$]).pipe(
   filterNilValue(),
   map(([organisationContext, organisations]) => (organisationContext?.id ? getParentIds(organisationContext, [], organisations) : [])),
   distinctUntilChanged(isEqual)
);

export const hasParents$ = organisationContextQuery
   .select((state) => state?.activeOrganisation?.parents ?? [])
   .pipe(
      map((parents) => parents?.length > 0),
      distinctUntilChanged()
   );

export const reducedContext$ = context$.pipe(
   map((org) => ({
      ...pick(org, [
         'id',
         'slug',
         'name',
         'code',
         'title',
         'favicon',
         'banner',
         'logo',
         'isWhiteLabel',
         'background',
         'theme',
         'allowMaterialityAssessment',
      ]),
      label: org?.code ? `(${org.code}) ${org?.name}` : org?.name,
   })),
   distinctUntilChanged(isEqual)
);

export const customization$ = context$.pipe(
   map((org) => org?.customization),
   distinctUntilChanged(isEqual)
);

export const theme$ = context$.pipe(
   map((org) => org?.theme),
   distinctUntilChanged(isEqual)
);
