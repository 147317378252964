import { buildMongoQueryMatcher, createAliasResolver, createMongoAbility } from '@casl/ability';
import { $and, and } from '@ucast/mongo2js';

const resolveAction = createAliasResolver({
  write: ['update', 'create'],
  edit: ['update'],
});

const conditionsMatcher = buildMongoQueryMatcher({ $and }, { and });

export default createMongoAbility([], { resolveAction, conditionsMatcher });
