import { createQuery, filterNilValue } from '@datorama/akita';
import userContextStore from 'context/UserContext/store';
import LanguageCodes from 'resources/json/LanguageCodes.json';
import { distinctUntilChanged, map } from 'rxjs';

export const userContextQuery = createQuery(userContextStore);

export const context$ = userContextQuery.select();

export const userId$ = userContextQuery.select('id');
export const notifications$ = userContextQuery.select('notifications');
export const organisations$ = userContextQuery.select('organisations');
export const reportingStandards$ = userContextQuery.select('reportingStandards');
export const reportingPeriods$ = userContextQuery.select('periods');

export const pageSize$ = userContextQuery.select('pageSize').pipe(filterNilValue(), distinctUntilChanged());
export const locale$ = userContextQuery.select('locale');
export const language$ = userContextQuery.select('lang');
export const supportedLanguage$ = language$.pipe(map((language) => (LanguageCodes.map(({ value }) => value).includes(language) ? language : 'en')));
export const includeSubsidiaries$ = userContextQuery.select('includeSubsidiaries');
export const loading$ = userContextQuery.selectLoading();
export const groups$ = userContextQuery.select('groups');
export const rules$ = userContextQuery.select('rules');
export const layouts$ = userContextQuery.select('layouts');

export const reducedContext$ = userContextQuery.select(['id', 'firstName', 'lastName', 'userName', 'email', 'oAuthProvider', 'image', 'publicKeys']);
