import { OrganisationsView } from 'utils/enum';

const initialState = {
   activeDashboardTab: undefined,
   tabEditMode: false,

   chartsPeriodFilter: {
      from: null,
      to: null,
   },
   aggregationLevelPerTab: {
      overview: 'YEAR',
   },
   aggregationTagsPerTab: {
      overview: [],
   },

   kpiEditMode: false,
   taxonomyEditMode: false,
   taskEditMode: false,
   groupsEditMode: false,
   footprintEditMode: false,

   showModalCreateOrUpdateChart: false,
   showModalAddChartItem: false,
   showDashboardSidebar: false,

   contentSidebarInfoExpanded: false,
   contentSidebarCommentsExpanded: false,
   contentSidebarTasksExpanded: false,
   contentSidebarAttachmentsExpanded: false,
   contentSidebarFieldLinksExpanded: false,

   showModalSupplierAssessmentCreateOrUpdate: false,
   showModalSupplierSiteCreateOrUpdate: false,
   showModalSupplierCreate: false,
   showModalSettingsEntities: false,
   showModalForgotPassword: false,
   showModalSignup: false,
   showModalEditColumnKPIMeta: false,
   showModalKPIScopeDialogGRI: false,
   showModalKPIScopeDialogESRS: false,
   showModalKPIScopeDialog: false,
   showModalTaxonomyGuidanceEdit: false,
   showModalTaxonomyAuditorGuidanceEdit: false,
   showModalRenameColumn: false,
   showModalManageFSLIs: false,
   showModalSubsidiaryValues: false,
   showModalKPIAreaCreateOrUpdate: false,
   showModalKPICreateOrUpdate: false,
   showModalKPISubCreate: false,
   showModalKPISubUpdate: false,
   showModalKPISubCreateOrUpdate: false,
   showModalKPIContentCreateOrUpdate: false,
   showModalKPIFieldCreate: false,
   showModalKPIFieldCreateOrUpdate: false,
   showModalKPIFieldEdit: false,
   showModalCreateOrUpdateComment: false,
   showModalUpdateCurrencies: false,
   showModalUserCreate: false,
   showModalUserCreatePassword: false,
   showModalCreateRule: false,
   showModalKPIHeaderInfo: false,
   showModalKPISubHeaderInfo: false,
   showModalKPIValueVersions: false,
   showModalKPIValueItems: false,
   showModalCreateReportOrDocument: false,
   showModalEditReportOrDocument: false,
   showModalMaterialityScoping: false,
   showModalTaxonomyAssignmentReason: false,
   showModalDataImport: false,
   showModalImportDefinition: false,
   showModalDashboardAddTab: false,
   showModalCreateGroupMember: false,
   showModalTaxonomyInfo: false,
   showModalCreateOrUpdateBusinessActivities: false,
   showModalCreateOrUpdateFootprint: false,
   showModalCreateOrUpdateCalculation: false,
   showModalEmissionMetada: false,
   showModalPeriodSelection: false,
   showModalOrganisationSelection: false,
   showContentSidebar: false,
   showHistorySidebar: false,
   showAvatarCropper: false,
   showEmissionFactorsSidebar: false,
   showModalEmissionCategoryCreateOrUpdate: false,
   showModalEmissionFactorCreateOrUpdate: false,
   showModalEmissionSourceCreateOrUpdate: false,
   showModalEmissionTemplateUpdate: false,
   showModalFootprintSubCreateOrUpdate: false,
   showModalFootprintSubDelete: false,
   showModalCreateFootprintTemplate: false,
   showModalEditLoginOptions: false,
   showLoadingOverlay: false,
   showModalCreateOrUpdateCalculationField: false,
   showCreateTaskModal: false,
   showCreateActionModal: false,
   showModalTaskStaffModel: false,
   showModalProductCreateOrUpdate: false,
   showModalMaterialCreateOrUpdate: false,
   showModalValidationRules: {
      open: false,
      type: null,
      id: null,
   },
   showModalGenericEntityAuditors: {
      open: false,
      role: 'AUDITOR',
   },
   showModalRejectAuditItem: {
      open: false,
      type: null,
      id: null,
   },
   showModalRollForwardPeriod: false,
   showModalClosingPeriod: false,
   showModalKPIFieldGroupRowEdit: false,
   showModalKPIFieldNameColumnEdit: false,

   modalSettingsPeriods: {
      open: false,
      type: null,
   },
   modalSettingsStandards: {
      open: false,
      type: null,
   },
   modalSettingsDocumentTypes: {
      open: false,
      type: null,
   },
   modalSettingsDocumentTemplates: {
      open: false,
      type: null,
   },
   modalDocumentCreateOrUpdate: {
      open: false,
      type: null,
   },
   modalReportCreateOrUpdate: {
      open: false,
      type: null,
   },
   modalSettingsUserGroups: {
      type: null,
      open: false,
      templateGroup: null,
   },
   modalSettingsTags: {
      open: false,
      type: null,
   },

   organisationsView: OrganisationsView.OrganisationList,
   organisationsViewTopbar: null,
   taxonomyDisclosureType: 'REV',

   kpiShowInfo: false,
   mainNavigationOpen: false,

   modalTaxonomyInfoId: undefined,
   footprintSubIdToDelete: undefined,
   activeModalTaskStaffModel: undefined,
   activeReportingStandardId: undefined,

   tutorialStep: 0,

   searchTaxonomyList: '',
   searchUserList: '',
   searchOrganizationList: '',
   searchEmissionFactorList: '',
   searchEmissionSourceList: '',
   searchEmissionCategoryList: '',
   searchEmissionTemplateList: '',
   searchTasksList: '',
   searchKPIScopeList: '',
   searchBusinessActivityList: '',
   searchBusinessActivityTaxonomyActivity: [],
   searchBusinessActivityResultType: [],
   searchBusinessActivityStatus: [],
   searchSupplierSiteList: '',
   searchSupplierMaterialList: '',
   profileEditMode: false,
   showUpdateTaskDialog: false,
   showCreateEditGoalModal: false,
   showCreateUpdateSupplyChainActionModal: false,

   reportOnlyApproved: false,
   reportShowEmptyFields: false,
   reportGroupByOrganisation: false,
   keyFigureReportReportingStandardIds: [],
   keyFigureTagIds: [],
   keyFigureExpandAll: null,
   emissionsReportFootprintIdentifier: undefined,

   showAllDNSHCriteria: true,

   showSupplyChainProductView: false,
   showModalEmissionTemplateImport: false,
   taskEditComment: false,
   genericDropZoneDialog: null,
   showAddSupplierModal: false,
   searchSupplierList: '',
   searchProductList: '',
   searchMaterialList: '',
   showDeletedOrg: false,
   showDeletedUsers: false,
   showDeletedImportJobs: false,

   isKPIValueItemsTableDirty: false,
   statisticsFilters: {
      periods: [],
      reportingStandards: [],
      organisations: [],
   },
   KPITableFieldInGridMode: false,
};

export default initialState;
