import { createEntityStore } from '@datorama/akita';

const store = createEntityStore(
   {
      businessActivities: [],
      filter: undefined,
      sortModel: {
         field: 'updatedAt',
         sort: 'desc',
      },
   },
   { resettable: true,name: 'taxonomy/fslis' }
);
export default store;
