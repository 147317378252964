import { applyTransaction, arrayRemove, arrayUpdate, combineQueries, filterNilValue, resetStores } from '@datorama/akita';
import * as Sentry from '@sentry/react';
import ability from 'casl/ability';
import { layoutPropsToSave } from 'components/Chart/layoutConfig';
import { pageSize$, userContextQuery } from 'context/UserContext/query';
import userContextStore from 'context/UserContext/store';
import { pick } from 'lodash-es';
import { debounceTime, distinctUntilChanged, firstValueFrom, map, of, tap } from 'rxjs';
import { businessActivitiesStore } from 'state/BusinessActivities/store';
import CRUDService from 'state/CRUDService';
import fsliStore from 'state/FSLI/store';
import orgStore from 'state/Organisations/store';
import taskStore from 'state/Task/store';
import { query as uiQuery } from 'state/UI/query';
import uiStore from 'state/UI/store';
import { query } from 'state/User/query';
import store from 'state/User/store';
import baseClient from 'utils/axiosBaseClient';

const persistableViewStateProperties = [
   'activeDashboardTab',
   'organisationsView',
   'organisationsViewTopbar',
   'mainNavigationOpen',
   'keyFigureReportReportingStandardIds',
   'keyFigureTagIds',
   'reportGroupByOrganisation',
   'reportShowEmptyFields',
   'reportOnlyApproved',
   'statisticsFilters',
   'KPITableFieldInGridMode',
];

export default class UsersService extends CRUDService {
   constructor() {
      if (!UsersService.instance) {
         super('users', store, query, [], true, false, false, false, true, true);
         this.contextStore = userContextStore;
         this.uiStore = uiStore;
         this.fsliStore = fsliStore;
         this.baseClient = baseClient;
         this.hasLoaded = false;
         this.userPromise = undefined;

         UsersService.instance = this;
      }

      // UsersService shall be instantiated only once, because otherwise the observable will be created for each service instance

      return UsersService.instance;
   }

   async logout() {
      try {
         await this.persistViewState();
         this.resetHasLoaded();

         await this.baseClient.post('/auth/logout');
         ability.update([]);
         Sentry.setUser(null);
         ['organisationId', 'activeOrganisationId', 'activePeriodId'].forEach((property) => Sentry.setContext(property, null));
         resetStores({ exclude: ['design', 'applicationContext', 'loginOptions'] });

         return true;
      } catch {
         return false;
      }
   }

   async getLoggedInUser() {
      this.contextStore.setLoading(true);
      if (this.userPromise) {
         return this.userPromise;
      }
      this.userPromise = this.httpClient
         .get(`/${this.version}/users/self`)
         .then((resp) => {
            Sentry.setUser({ ...pick(resp.data, ['id', 'email']), username: resp.data?.userName });

            ['organisationId', 'activeOrganisationId', 'activePeriodId'].forEach((property) => Sentry.setContext(property, resp.data?.[property]));

            this.appendDataToStore(resp.data, this.contextStore);

            ability.update(resp.data.rules);

            // update persisted store states
            const { fsliFilter, sortPref } = resp.data?.viewState ?? {};
            uiStore.update(resp.data.viewState);
            fsliStore.update({ filter: fsliFilter });
            store.update({ sort: sortPref?.userTable });
            orgStore.update({ sort: sortPref?.orgTable });
            businessActivitiesStore.update({ sort: sortPref?.businessActivities });
            taskStore.update({ sortOrder: sortPref?.taskActions });

            this.contextStore.setLoading(false);
            this.userPromise = undefined;
            return resp.data;
         })
         .catch((error) => {
            if (error?.response?.status === 401) {
               this.hasLoaded = true;

               Sentry.setUser(null);
               ['organisationId', 'activeOrganisationId', 'activePeriodId'].forEach((property) => Sentry.setContext(property, null));
            } else {
               this.setError(error);
               this.contextStore.setLoading(false);
               this.userPromise = undefined;
            }
            return false;
         });

      return this.userPromise;
   }

   resetHasLoaded() {
      this.hasLoaded = false;
   }

   async isAuthenticated() {
      let storeValue = this.userContextQuery.getValue();

      if (storeValue?.id) {
         return true;
      }
      if (this.hasLoaded) {
         return false;
      }

      const isAuthenticated = await this.getLoggedInUser();

      if (isAuthenticated === false) {
         return false;
      }
      storeValue = this.userContextQuery.getValue();
      return !!storeValue?.id;
   }

   setActiveOrganisation = async (organisationId) => {
      const userId = this.userContextQuery.getValue().id;

      this.contextStore.setLoading(true);

      return this.httpClient
         .patch(`/${this.version}/users/${userId}`, {
            activeOrganisationId: organisationId,
         })
         .then((resp) =>
            applyTransaction(() => {
               this.contextStore.update((prevState) => ({
                  ...prevState,
                  ...resp.data,
               }));

               this.contextStore.setLoading(false);
            })
         )
         .catch((error) => {
            this.setError(error, this.contextStore);
            this.contextStore.setLoading(false);
         });
   };

   markNotificationAsRead = async (notificationId) => {
      const collectionName = 'notifications';

      return this.httpClient
         .post(`/${this.version}/notifications/${notificationId}/markasread`)
         .then(() =>
            this.contextStore.update(({ [collectionName]: collectionName_ }) => ({
               notifications: arrayUpdate(collectionName_, notificationId, { isRead: true }),
            }))
         )
         .catch((error) => {
            this.setError(error, this.contextStore);
         });
   };

   markAllNotificationsAsRead = async () => {
      const collectionName = 'notifications';
      const userId = this.userContextQuery.getValue().id;

      return this.httpClient
         .post(`/${this.version}/users/${userId}/notifications/readall`)
         .then(() =>
            this.contextStore.update(({ [collectionName]: collectionName_ }) => ({
               notifications: arrayUpdate(collectionName_, () => true, { isRead: true }),
            }))
         )
         .catch((error) => {
            this.setError(error, this.contextStore);
         });
   };

   deleteNotification = async (notificationId) => {
      const collectionName = 'notifications';

      return this.httpClient
         .delete(`/${this.version}/${collectionName}/${notificationId}`)
         .then(() =>
            this.contextStore.update(({ [collectionName]: collectionName_ }) => ({
               [collectionName]: arrayRemove(collectionName_, notificationId),
            }))
         )
         .catch((error) => {
            this.setError(error, this.contextStore);
         });
   };

   setActivePeriod = async (periodId) => {
      this.contextStore.setLoading(true);

      const userId = this.userContextQuery.getValue().id;

      return this.httpClient
         .patch(`/${this.version}/users/${userId}`, {
            activePeriodId: periodId,
         })
         .then((resp) => {
            this.contextStore.update((prevState) => ({
               ...prevState,
               ...resp.data,
            }));
            return this.contextStore.setLoading(false);
         })
         .catch((error) => {
            this.setError(error, this.contextStore);
            this.contextStore.setLoading(false);
         });
   };

   setIncludeSubsidiaries = async (includeSubsidiaries) => {
      this.contextStore.setLoading(true);

      const userId = this.userContextQuery.getValue().id;

      return this.httpClient
         .patch(`/${this.version}/users/${userId}`, {
            includeSubsidiaries,
         })
         .then((resp) => {
            this.contextStore.update((prevState) => ({
               ...prevState,
               ...resp.data,
            }));
            return this.contextStore.setLoading(false);
         })
         .catch((error) => {
            this.setError(error, this.contextStore);
            this.contextStore.setLoading(false);
         });
   };

   persistViewState = async () => {
      const userId = this.userContextQuery.getValue()?.id;

      if (userId) {
         const lastViewState = this.userContextQuery.getValue()?.viewState ?? {};
         const currentViewState = pick(uiStore.getValue(), persistableViewStateProperties);
         const persistableStoreState = {
            fsliFilter: fsliStore.getValue().filter,
            sortPref: {
               userTable: store.getValue().sort,
               orgTable: orgStore.getValue().sort,
               businessActivities: businessActivitiesStore.getValue().sort,
               taskActions: taskStore.getValue().sortOrder,
            },
         };

         if (currentViewState?.previousOrganisationId) {
            currentViewState.activeOrganisationId = currentViewState.previousOrganisationId;
         }

         return this.httpClient.patch(`/${this.version}/users/${userId}`, {
            viewState: {
               ...lastViewState,
               ...currentViewState,
               ...persistableStoreState,
            },
         });
      }
   };

   setLayout = async (tabName, layout) => {
      if (
         Array.isArray(layout) &&
         layout.length > 0 &&
         !layout.every((layoutItem, index) => layoutItem.w === 1 && layoutItem.h === 1 && layoutItem.x === 0 && layoutItem.y === index)
      ) {
         const { id: userId, layouts: userLayout, activeOrganisationId } = await firstValueFrom(userContextQuery.select());

         if (
            !userLayout?.[activeOrganisationId] ||
            !(tabName in userLayout[activeOrganisationId]) ||
            layout.some((newLayout) => {
               const previousLayout = (userLayout?.[activeOrganisationId]?.[tabName] ?? []).find((prevLayout) => prevLayout.i === newLayout.i);

               return (
                  previousLayout?.x !== newLayout?.x ||
                  previousLayout?.y !== newLayout?.y ||
                  previousLayout?.w !== newLayout?.w ||
                  previousLayout?.h !== newLayout?.h
               );
            })
         ) {
            this.contextStore.setLoading(true);

            const _layout = (layout ?? [])?.map((l) => pick(l, layoutPropsToSave));
            return this.httpClient
               .patch(`/${this.version}/users/${userId}`, {
                  layouts: {
                     ...userLayout,
                     [activeOrganisationId]: {
                        ...userLayout?.[activeOrganisationId],
                        [tabName]: Array.from(_layout),
                     },
                  },
               })
               .then((resp) => {
                  this.appendDataToStore(resp.data, this.contextStore);
                  return this.contextStore.setLoading(false);
               })
               .catch((error) => {
                  this.setError(error, this.contextStore);
                  this.contextStore.setLoading(false);
               });
         }
      }

      return undefined;
   };

   async updateUserImage(file, userId) {
      const formData = new FormData();
      formData.append('image', file[0]);

      return this.httpClient
         .post(`/${this.version}/users/${userId}/image`, formData, { 'Content-Type': 'multipart/form-data' })
         .then((resp) => {
            if (this.userContextQuery.getValue().id === userId) {
               this.contextStore.update(resp.data);
            }
            return this.store.update(userId, resp.data);
         })
         .catch((error) => this.setError(error, this.contextStore));
   }

   async setPageSize(pageSize) {
      const userId = this.userContextQuery.getValue().id;

      return this.httpClient
         .patch(`/${this.version}/users/${userId}`, { pageSize })
         .then((resp) => {
            if (this.userContextQuery.getValue().id === userId) {
               this.contextStore.update(resp.data);
            }
            return this.store.update(userId, resp.data);
         })
         .catch((error) => this.setError(error, this.contextStore));
   }

   async toggleNotificationTypeActive(userId, notificationType, active) {
      await this.updateEntityCollection(userId, 'notificationSettings', 'all', { [notificationType]: active });
      this.updateUserContextIfModified(userId);
   }

   async updateNotifications(userId, notificationType, notificationValue, notificationId) {
      await this.updateEntityCollection(userId, 'notificationSettings', notificationId, { [notificationType]: notificationValue });
      this.updateUserContextIfModified(userId);
   }

   async updateUserContextIfModified(entityId) {
      const userId = this.userContextQuery.getValue().id;
      if (entityId === userId) {
         this.getLoggedInUser();
      }
   }

   async updateEntity(entityId, changes) {
      await super.updateEntity(entityId, changes);
      this.updateUserContextIfModified(entityId);
   }

   showTutorial = async () => {
      const userId = this.userContextQuery.getValue().id;
      this.contextStore.update(() => ({ tutorialDone: false }));
      return Promise.resolve(this.updateEntity(userId, { tutorialDone: false }));
   };

   hideTutorial = async () => {
      const userId = this.userContextQuery.getValue().id;
      this.contextStore.update(() => ({ tutorialDone: true, tutorialStep: 0 }));
      return Promise.resolve(this.updateEntity(userId, { tutorialDone: true }));
   };

   setSearchTerm(searchTerm) {
      this.store.update({ searchTerm });
   }

   setSortOrder(sort) {
      this.store.update({ sort });
   }

   getPageParams() {
      const dependencies = [];

      dependencies.push(pageSize$);
      dependencies.push(this.query.select('searchTerm').pipe(debounceTime(800)));
      dependencies.push(uiQuery.select('showDeletedUsers'));
      dependencies.push(this.query.select('sort'));

      let urlParams;

      if (dependencies.length) {
         return combineQueries(dependencies).pipe(
            filterNilValue(),
            distinctUntilChanged(),
            map(([limit, text, showDeleted, sort]) => {
               urlParams = new URLSearchParams();

               if (limit) {
                  urlParams.set('limit', limit);
               }
               if (showDeleted !== undefined) {
                  urlParams.set('deleted', showDeleted);
               }
               if (text !== undefined) {
                  if (text?.length > 0) {
                     urlParams.set('text', text);
                     urlParams.set('includeSubsidiaries', true);
                  }
                  urlParams.set('organisationId', this.userContextQuery.getValue().organisation?.id);
               }
               if (sort?.key) {
                  urlParams.set('sort', `${sort.key}:${sort.order ?? 'asc'}`);
               }

               return `?${urlParams.toString()}`;
            }),
            distinctUntilChanged(),
            tap((pageQueryParams) => {
               this.pageQueryParams = pageQueryParams;
               if (this.paginate && this.paginator.pages.size > 0) {
                  this.paginator.clearCache();
                  this.paginator.clearCache();
               }
            })
         );
      }

      urlParams = new URLSearchParams();

      return of(`?${urlParams.toString()}`);
   }

   async deleteCredential(credentialId) {
      const userId = this.userContextQuery.getValue().id;

      return this.httpClient
         .delete(`/${this.version}/users/${userId}/credentials/${credentialId}`)
         .then(() =>
            this.contextStore.update(({ publicKeys: publicKeys_ }) => ({
               publicKeys: arrayRemove(publicKeys_, (item) => item.credentialId === credentialId),
            }))
         )
         .catch((error) => this.setError(error, this.contextStore));
   }

   async restoreUser(userId) {
      return this.httpClient
         .post(`/${this.version}/${this.entityName}/${userId}/restore`)
         .then((resp) => this.store.update(userId, resp.data))
         .catch((error) => this.setError(error));
   }

   async getSuggestions(key = null, page = 1, limit = 25) {
      this.store.setLoading(true);
      const urlParams = new URLSearchParams();

      if (this.useScope === true) {
         urlParams.set('scope', true);
      } else if (this.useScope === false) {
         urlParams.set('scope', false);
      } else {
         urlParams.set('scope', 'any');
      }
      if (key) {
         urlParams.set('text', key);
      }
      urlParams.set('organisationId', this.userContextQuery.getValue().organisation?.id);
      urlParams.set('limit', limit ?? this.userContextQuery.getValue().pageSize);
      urlParams.set('page', page);
      urlParams.set('includeSubsidiaries', true);
      urlParams.set('deleted', false);

      const queryString = `?${urlParams.toString()}`;

      return this.httpClient
         .get(`/${this.version}/${this.entityName}${queryString}`)
         .then((resp) => resp.data)
         .catch((error) => this.setError(error))
         .finally(() => this.store.setLoading(false));
   }

   async setUserLocale(locale) {
      const userId = this.userContextQuery.getValue().id;

      return this.httpClient
         .patch(`/${this.version}/users/${userId}`, { locale })
         .then((resp) => {
            if (this.userContextQuery.getValue().id === userId) {
               this.contextStore.update(resp.data);
            }
            return this.store.update(userId, resp.data);
         })
         .catch((error) => this.setError(error, this.contextStore));
   }

   async export(organisationId, includeSubsidiaries) {
      if (!this.queryParams) {
         await firstValueFrom(this.queryParamsObservable);
      }
      const urlParams = new URLSearchParams(this.queryParams);
      urlParams.set('organisationId', organisationId);
      urlParams.set('includeSubsidiaries', includeSubsidiaries);

      return this.httpClient
         .get(`/${this.version}/users/export/excel?${urlParams.toString()}`, { responseType: 'blob' })
         .then((resp) => resp)
         .catch((error) => {
            this.setError(error);
         });
   }
}
