import { subject } from '@casl/ability';
import { arrayMove } from '@dnd-kit/sortable';
import { Box, Link, MenuItem, Tooltip, Typography } from '@mui/material';
import ability from 'casl/ability';
import Icon from 'components/Icon';
import { Currencies } from 'currencies-map';
import {
   addDays,
   areIntervalsOverlapping,
   format,
   formatISO,
   getMonth,
   getQuarter,
   intervalToDuration,
   isDate,
   isSameQuarter,
   isValid,
   isWithinInterval,
   max,
   min,
   parseISO,
} from 'date-fns';
import parse, { domToReact } from 'html-react-parser';
import Qty from 'js-quantities';
import { findLastIndex, get, merge, omit, orderBy, pick, uniq } from 'lodash-es';
import { evaluate, unit } from 'mathjs';
import { useCallback, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import ELITypeMapping from 'resources/json/ELITypeMapping.json';
import regionCodeMapping from 'resources/json/RegionCodes.json';
import sanctionedUnits from 'resources/json/sanctionedUnits.json';
import {
   ApprovalStatus,
   EntityRelationType,
   ImportJobTypes,
   KPIFieldType,
   KPIValueType,
   OrganisationType,
   ReferencedArea,
   SearchResultKind,
   TaskCycle,
   TaskStatus,
   TaxonomyAlignmentType,
   TaxonomyContributionType,
} from 'utils/enum';

const SUCCESS_COLOR = 'success.main';

const romanNumerals = ['i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix', 'x'];
const romanNumeralsFullRegexStr = '(?=[MDCLXVI])M*(?:C[MD]|D?C{0,3})(?:X[CL]|L?X{0,3})(?:I[XV]|V?I{0,3})';
export const stripRomanNumeralsListIndex = (str) => {
   return str.replace(new RegExp(String.raw`^${romanNumeralsFullRegexStr}\.\s?`, 'gim'), '');
};

const unitReplacement = {
   tonne: 't',
   'room-night': null,
   'CPU-hour': 'h',
   'instance-hour': 'h',
   'passenger-km': 'km',
   'passenger-mile': 'mile',
   'person-night': null,
   'gal (US)': 'gal',
   L: 'l',
   'container-moved': null,
};

function taxonomyReplaceFunction(domNode, lang = 'en') {
   const language = Object.keys(ELITypeMapping.general).includes(lang) ? lang : 'en';

   if (domNode.type === 'tag' && domNode.name === 'a') {
      return (domNode?.attribs?.href ?? '').startsWith('#') ? (
         <Link component={HashLink} to={domNode.attribs.href} smooth title={domNode.attribs.title}>
            {domToReact(domNode.children)}
         </Link>
      ) : (
         <Link href={domNode.attribs.href} title={domNode.attribs.title} rel="noreferrer" target="_blank">
            {domToReact(domNode.children)}
         </Link>
      );
   }
   if (domNode.type === 'text') {
      Object.entries(ELITypeMapping)
         .filter(([key]) => key !== 'general')
         .forEach(([eliDocType, langMapping]) => {
            // eslint-disable-next-line security/detect-non-literal-regexp
            const regExp = new RegExp(
               `(?<=${langMapping?.[language] ?? langMapping?.en ?? ''}${
                  ELITypeMapping.general?.[language] ?? ELITypeMapping.general?.en ?? ''
               })\\d{3,4}\\/\\d{2,4}(\\/\\w*)?`,
               'gim'
            );

            const matches = domNode.data.match(regExp);

            if (Array.isArray(matches)) {
               matches.forEach((match) => {
                  const year = match.match(/(?:19|20)\d{2}/g);

                  if (Array.isArray(year) && year.length > 0) {
                     const documentNo = match
                        .trim()
                        .replace('/', '')
                        .replace(year[0], '')
                        .replace(/\/\w*$/, '');

                     if (documentNo) {
                        domNode.data = domNode.data.replace(
                           match,
                           ` <a target="_blank" rel="noreferrer" href="https://eur-lex.europa.eu/eli/${eliDocType}/${year[0]}/${documentNo}">${match}</a>`
                        );
                     }
                  }
               });
            }
         });

      return <span>{parse(domNode.data)}</span>;
   }

   return undefined;
}

function truncateNumber(numToTruncate, decimalPlaces = 100) {
   // eslint-disable-next-line security/detect-non-literal-regexp
   const re = new RegExp(`(\\d+\\.\\d{${decimalPlaces}})(\\d)`);
   const m = numToTruncate.toString().match(re);
   return m ? parseFloat(m[1]) : numToTruncate.valueOf();
}

export const truncateText = (text = '', delimeter = 100) => (text ?? '').substring(0, delimeter) + ((text ?? '').length > delimeter ? '...' : '');

function getDecimalSeparator(locale) {
   const number = 1.1;

   return Intl.NumberFormat(locale)
      .formatToParts(number)
      .find((part) => part.type === 'decimal').value;
}

function getGroupingSeparator(locale) {
   const number = 10000;

   return (
      Intl.NumberFormat(locale)
         .formatToParts(number)
         .find((part) => part.type === 'group')?.value ?? ''
   );
}

async function handleDrag(active, over) {
   if (active?.data?.current?.type && Array.isArray(over?.data?.current?.accepts) && over.data.current.accepts.includes(active.data.current.type)) {
      let parentKey;
      let collectionName;
      let parentEntityName;

      switch (active.data.current.type) {
         case 'footprintSub':
            parentKey = 'footprintId';
            collectionName = 'subs';
            parentEntityName = 'emissions/ghg/footprints';
            break;
         case 'fpCalculation':
            parentKey = 'footprintSubId';
            collectionName = 'calculations';
            parentEntityName = 'emissions/ghg/footprintssubs';
            break;
         case 'fpCalculationField':
            parentKey = 'fpCalculationId';
            collectionName = 'fields';
            parentEntityName = 'emissions/ghg/calculations';
            break;
         case 'kpi':
            parentKey = 'kpiAreaId';
            collectionName = 'kpis';
            parentEntityName = 'kpiAreas';
            break;
         case 'kpiSub':
            parentKey = 'kpiId';
            collectionName = 'kpisubs';
            parentEntityName = 'kpis';
            break;
         case 'kpiContent':
            parentKey = 'kpiSubId';
            collectionName = 'kpicontents';
            parentEntityName = 'kpisubs';
            break;
         case 'kpiField':
            parentKey = 'kpiContentId';
            collectionName = 'kpifields';
            parentEntityName = 'kpicontents';
            break;
         default:
            break;
      }

      // rearrange in the same container
      if (active.id !== over.id && active.data.current.sortable.containerId === over.data.current.sortable.containerId) {
         const oldIndex = active.data.current.sortable.items.indexOf(active.id);
         const newIndex = over.data.current.sortable.items.indexOf(over.id);

         active.data.current.api.sortEntities(
            arrayMove(active.data.current.sortable.items, oldIndex, newIndex),
            parentEntityName,
            `${active.data.current.type}Id` === parentKey ? active.id : active.data.current.sortable.containerId,
            collectionName
         );
         // move to a different container
      } else if (active.data.current.sortable.containerId !== over.data.current.sortable.containerId) {
         await active.data.current.api.updateEntity(active.id, {
            [parentKey]: `${over.data.current.type}Id` === parentKey ? over.id : over.data.current.sortable.containerId,
         });
         over.data.current.api.sortEntities(
            over.data.current.sortable.items.splice(over.data.current.sortable.index, 0, active.id),
            parentEntityName,
            `${over.data.current.type}Id` === parentKey ? over.id : over.data.current.sortable.containerId,
            collectionName
         );
         active.data.current.api.sortEntities(
            active.data.current.sortable.items.splice(active.data.current.sortable.items.indexOf(active.id), 1),
            parentEntityName,
            `${active.data.current.type}Id` === parentKey ? active.id : active.data.current.sortable.containerId,
            collectionName
         );
      }
   }
}

function getEntityKindIcon(kind) {
   let icon;

   switch (kind) {
      case 'all':
         icon = 'search';
         break;
      case 'emissionfactor':
         icon = 'calculator-simple';
         break;
      case 'kpiarea':
      case 'kpi':
      case 'kpisub':
      case 'kpicontent':
      case 'kpifield':
         icon = 'pen-field';
         break;
      case 'businessactivity':
         icon = 'briefcase';
         break;
      case 'taxonomyactivity':
         icon = 'leaf';
         break;
      case 'emissioncategory':
         icon = 'smoke';
         break;
      case 'organisation':
         icon = 'sitemap';
         break;
      case 'chart':
         icon = 'chart-column';
         break;
      case 'report':
         icon = 'book';
         break;
      case 'reportdocument':
         icon = 'file-word';
         break;
      case 'reportingstandard':
         icon = 'file-certificate';
         break;
      case 'task':
         icon = 'list-check';
         break;
      case 'user':
         icon = 'user';
         break;
      case 'group':
         icon = 'user-group';
         break;
      case 'currency':
         icon = 'coins';
         break;
      case 'footprint':
         icon = 'shoe-prints';
         break;
      case 'footprintcalculation':
      case 'fpcalculation':
         icon = 'calculator';
         break;
      case 'footprintcalculationfield':
      case 'fpcalculationfield':
         icon = 'shoe-prints';
         break;
      case 'refArea':
         icon = 'bullseye';
         break;
      case 'attachment':
      case 'taxonomyresponseattachment':
         icon = 'attachment';
         break;
      case 'taxonomycriteriaresponse':
         icon = 'leaf';
         break;
      default:
         break;
   }

   return <Icon icon={icon} fontSize="small" />;
}

function getOrganisationIcon(organisationType) {
   let icon;

   switch (organisationType) {
      case OrganisationType.REGION:
         icon = 'globe';
         break;
      case OrganisationType.COMMUNE:
         icon = 'users';
         break;
      case OrganisationType.AREA:
         icon = 'layer-group';
         break;
      case OrganisationType.PRODUCT:
         icon = 'tube-chemistry';
         break;
      case OrganisationType.PORTFOLIO:
         icon = 'folder-open';
         break;
      case OrganisationType.INVESTMENT:
         icon = 'hand-holding-usd';
         break;
      case OrganisationType.ORGANIZATION:
      default:
         icon = 'building';
         break;
   }

   return <Icon icon={icon} />;
}

function getOrganisationIconKey(organisationType) {
   let icon;

   switch (organisationType) {
      case OrganisationType.REGION:
         icon = 'globe';
         break;
      case OrganisationType.COMMUNE:
         icon = 'users';
         break;
      case OrganisationType.AREA:
         icon = 'layer-group';
         break;
      case OrganisationType.PRODUCT:
         icon = 'tube-chemistry';
         break;
      case OrganisationType.PORTFOLIO:
         icon = 'folder-open';
         break;
      case OrganisationType.INVESTMENT:
         icon = 'hand-holding-usd';
         break;
      case OrganisationType.ORGANIZATION:
      default:
         icon = 'building';
         break;
   }

   return icon;
}

function getEntityRelationIcon(entityRelation) {
   let icon;

   switch (entityRelation) {
      case EntityRelationType.CUSTOMER:
         icon = 'truck-field';
         break;
      case EntityRelationType.SUPPLIER:
         icon = 'user-check';
         break;
      case EntityRelationType.SUBSIDIARY:
      default:
         icon = 'building';
         break;
   }

   return <Icon icon={icon} />;
}

function getReferencedAreaIcon(area) {
   let icon;

   switch (area) {
      case ReferencedArea.EMI:
         icon = 'shoe-prints';
         break;
      case ReferencedArea.EUT:
         icon = 'briefcase';
         break;
      case ReferencedArea.GEN:
         icon = 'bullseye';
         break;
      case ReferencedArea.KPI:
         icon = 'pen-field';
         break;
      case ReferencedArea.ORG:
         icon = 'building';
         break;
      case ReferencedArea.REP:
         icon = 'book';
         break;
      case ReferencedArea.SET:
         icon = 'cog';
         break;
      default:
         icon = 'bullseye';
         break;
   }

   return <Icon icon={icon} fontSize="small" />;
}

function localizeOptions(intl, optionsList) {
   return optionsList.map((option) => ({
      ...option,
      ...(option?.label
         ? {
              label: intl.formatMessage({
                 id: option.label.id,
                 defaultMessage: option.label.defaultMessage,
              }),
           }
         : {}),
   }));
}

function localizeLanguageOptions(optionsList) {
   return Object.entries(optionsList).map(([key]) => key);
}

function groupArrayByKey(list, keyGetter) {
   const map = new Map();
   list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
         map.set(key, [item]);
      } else {
         collection.push(item);
      }
   });
   return map;
}

function checkOverlappingPeriod(parents, periodsFrom, periodsTo) {
   const mappedParentIds = uniq(Array.from(parents.map((parent) => parent?.id)));
   const overlappingValues = [];

   if (mappedParentIds.length < parents.length) {
      const maxToPeriod = max(periodsTo.map((periodTo) => (isValid(parseISO(periodTo)) ? parseISO(periodTo) : null)));
      const minFromPeriod = min(periodsFrom.map((periodFrom) => (isValid(parseISO(periodFrom)) ? parseISO(periodFrom) : null)));
      mappedParentIds.forEach((mappedParentId) => {
         const parentRelations = parents.filter((_parent) => _parent?.id === mappedParentId);
         if (parentRelations.length > 1) {
            if (parentRelations.some((pR) => !pR?.validFrom && !pR?.validTo)) {
               return false;
            }

            parentRelations.forEach((parentRelation, index) => {
               const overlapping = parentRelations
                  .filter((pR, ix) => index !== ix)
                  .find((otherParentRelation) =>
                     areIntervalsOverlapping(
                        {
                           start: parentRelation?.validFrom ?? minFromPeriod,
                           end: parentRelation?.validTo ?? maxToPeriod,
                        },
                        {
                           start: otherParentRelation?.validFrom ?? minFromPeriod,
                           end: otherParentRelation?.validTo ?? maxToPeriod,
                        },
                        { inclusive: true }
                     )
                  );

               overlappingValues.push(!!overlapping);
            });
         }
      });
      return overlappingValues.some((item) => item === true) && overlappingValues.length > 0;
   } else {
      return false;
   }
}

function checkProportionPerParent(parents, periodsFrom, periodsTo) {
   const mappedParentIds = uniq(Array.from(parents.map((parent) => parent?.id)));
   const moreThan100 = [];
   if (mappedParentIds.length > 1) {
      const maxToPeriod = max(periodsTo.map((periodTo) => (isValid(parseISO(periodTo)) ? parseISO(periodTo) : null)));
      const minFromPeriod = min(periodsFrom.map((periodFrom) => (isValid(parseISO(periodFrom)) ? parseISO(periodFrom) : null)));
      mappedParentIds.forEach((mappedParentId) => {
         const otherParents = parents.filter((otherParent) => otherParent?.id !== mappedParentId);
         const mappedParents = parents.filter((mappedParent) => mappedParent?.id === mappedParentId);

         moreThan100.push(
            mappedParents.some((mappedParent, index) => {
               const overlapping = otherParents
                  .filter((pR, ix) => index !== ix)
                  .filter((otherParentRelation) =>
                     areIntervalsOverlapping(
                        {
                           start: mappedParent?.validFrom ?? minFromPeriod,
                           end: mappedParent?.validTo ?? maxToPeriod,
                        },
                        {
                           start: otherParentRelation?.validFrom ?? minFromPeriod,
                           end: otherParentRelation?.validTo ?? maxToPeriod,
                        },
                        { inclusive: true }
                     )
                  );
               if (overlapping) {
                  return (overlapping ?? []).reduce((total, value) => total + (value?.subsidiaryProportion ?? 0), 0) > 1;
               } else {
                  return false;
               }
            })
         );
      });
   } else {
      moreThan100.push(parents.some((parent) => (parent?.subsidiaryProportion ?? 0) > 1));
   }
   return !!moreThan100.some((item) => item === true) && moreThan100.length > 0;
}

function getCorrectOrganisationParent(organisation, period) {
   const parentValidPeriod = (organisation.parents ?? []).flatMap((_parent) => [_parent?.validFrom, _parent?.validTo]);
   if (parentValidPeriod.length === 0) {
      return [];
   }

   if (period?.from && period?.to) {
      const currentPeriodFrom = parseISO(period?.from);
      const currentPeriodTo = parseISO(period?.to);

      if (parentValidPeriod.length > 0) {
         const checkIfParentIsInPeriod = [];
         (organisation.parents ?? []).forEach((parent) => {
            let isValidFromInPeriod = false;
            let isValidToInPeriod = false;
            let validFromEqualOrBefore = false;
            let validToEqualAfterPeriod = false;
            let noValidationDateValidFrom = false;
            let noValidationDateValidTo = false;
            let notValidForPeriod = false;
            const from = isValid(parseISO(parent?.validFrom)) ? parseISO(parent?.validFrom) : null;
            const to = isValid(parseISO(parent?.validTo)) ? parseISO(parent?.validTo) : null;
            if (!from || !to) {
               noValidationDateValidFrom = from === null;
               noValidationDateValidTo = to === null;
            }
            if (from) {
               validFromEqualOrBefore = from <= currentPeriodFrom;
               isValidFromInPeriod = isWithinInterval(from, {
                  start: currentPeriodFrom,
                  end: currentPeriodTo,
               });
               if (!validFromEqualOrBefore && !isValidFromInPeriod && !to) {
                  notValidForPeriod = true;
               }
            }
            if (to) {
               validToEqualAfterPeriod = to >= currentPeriodTo;
               isValidToInPeriod = isWithinInterval(to, {
                  start: currentPeriodFrom,
                  end: currentPeriodTo,
               });
               if (!validToEqualAfterPeriod && !isValidToInPeriod && !from) {
                  notValidForPeriod = true;
               }
            }
            if (
               ((!validToEqualAfterPeriod && !isValidToInPeriod) || (!validFromEqualOrBefore && !isValidFromInPeriod)) &&
               !noValidationDateValidFrom &&
               !noValidationDateValidTo
            ) {
               notValidForPeriod = true;
            }
            checkIfParentIsInPeriod.push({
               ...parent,
               isValidFromInPeriod,
               isValidToInPeriod,
               validFromEqualOrBefore,
               validToEqualAfterPeriod,
               notValidForPeriod,
               noValidationDateValidFrom,
               noValidationDateValidTo,
            });
         });
         return checkIfParentIsInPeriod;
      }
   }
   return [];
}

function formatOrganisationName(organisation, period, intl, organisations, viewType, showCode = true) {
   if ((!period?.from && !period?.to) || organisations.length === 0) {
      return '';
   }
   if ((organisation?.parents ?? []).length === 0) {
      return organisation.code && showCode ? `${organisation.code}: ${organisation.name}` : organisation.name;
   }
   const organisationLabel = organisation.code && showCode ? `${organisation.code}: ${organisation.name}` : `${organisation.name}`;

   const parentsPerPeriod = getCorrectOrganisationParent(organisation, period);

   const parentsValidForPeriod = parentsPerPeriod.filter(
      ({
         validFromEqualOrBefore,
         validToEqualAfterPeriod,
         noValidationDateValidFrom,
         isValidFromInPeriod,
         noValidationDateValidTo,
         isValidToInPeriod,
      }) =>
         (validFromEqualOrBefore || noValidationDateValidFrom || isValidFromInPeriod) &&
         (validToEqualAfterPeriod || noValidationDateValidTo || isValidToInPeriod)
   );

   if (parentsValidForPeriod.length === 1) {
      const {
         isValidFromInPeriod,
         isValidToInPeriod,
         validFromEqualOrBefore,
         validToEqualAfterPeriod,
         noValidationDateValidFrom,
         noValidationDateValidTo,
         validFrom,
         validTo,
         subsidiaryProportion,
      } = parentsValidForPeriod[0];

      const fromattedProportion = intl.formatNumber(subsidiaryProportion ?? 0, {
         maximumFractionDigits: 2,
         style: 'percent',
      });

      if ((validFromEqualOrBefore || noValidationDateValidFrom) && (validToEqualAfterPeriod || noValidationDateValidTo)) {
         let formattedLabel = `${fromattedProportion}`;
         if (viewType === 'row') {
            formattedLabel = `${organisationLabel} (${fromattedProportion})`;
         }
         return <Typography> {formattedLabel} </Typography>;
      } else if (isValidFromInPeriod || isValidToInPeriod) {
         let validText = '';
         if (noValidationDateValidTo && isValidFromInPeriod) {
            validText = `${intl.formatMessage({
               id: 'organisations.validFrom',
               defaultMessage: 'valid from:',
            })} ${parseISO(validFrom).toLocaleDateString()}`;
         } else if (isValidToInPeriod && noValidationDateValidFrom) {
            validText = `${intl.formatMessage({
               id: 'organisations.validTo',
               defaultMessage: 'valid to:',
            })} ${parseISO(validTo).toLocaleDateString()}`;
         } else {
            validText = `${parseISO(validFrom).toLocaleDateString()} - ${parseISO(validTo).toLocaleDateString()}`;
         }
         return (
            <Typography>
               {viewType === 'row' ? organisationLabel : ''}
               {`${fromattedProportion} (${validText})`}
            </Typography>
         );
      }
   } else {
      let parentsArray = parentsValidForPeriod;
      if (parentsValidForPeriod.length === 0 && parentsPerPeriod.length > 0) {
         parentsArray = parentsPerPeriod;
      }

      parentsArray = orderBy(parentsArray, ['validFrom', 'validTo'], ['asc', 'asc']);
      const uniqParentIds = uniq(Array.from(parentsArray.map((parent) => parent?.id)));

      return (
         <>
            {viewType === 'row' && (
               <Typography paddingBottom={parentsArray.length > 0 ? 1 : 0} fontSize={14}>
                  {organisationLabel}
               </Typography>
            )}
            {(parentsArray ?? []).map((parent, index) => {
               const {
                  validFromEqualOrBefore,
                  validToEqualAfterPeriod,
                  noValidationDateValidFrom,
                  noValidationDateValidTo,
                  validFrom,
                  validTo,
                  isValidFromInPeriod,
                  isValidToInPeriod,
                  notValidForPeriod,
               } = parent;

               let parentName = '';
               if (uniqParentIds.length > 1) {
                  const parentObject = (organisations ?? []).find((org) => org?.id === parent?.id);
                  parentName = parentObject?.code && showCode ? `${parentObject?.code}: ${parentObject?.name}` : `${parentObject?.name}`;
               }
               const fromattedProportion = intl.formatNumber(parent?.subsidiaryProportion ?? 0, {
                  maximumFractionDigits: 2,
                  style: 'percent',
               });

               if ((validFromEqualOrBefore || noValidationDateValidFrom) && (validToEqualAfterPeriod || noValidationDateValidTo)) {
                  return (
                     <Typography paddingBottom={1} fontSize={12} key={`${parent?.parentId}_${index}`}>
                        {`${fromattedProportion} (${parentName})`}
                     </Typography>
                  );
               } else if (isValidFromInPeriod || isValidToInPeriod || notValidForPeriod) {
                  let validText = '';
                  if ((noValidationDateValidTo && isValidFromInPeriod) || (noValidationDateValidTo && notValidForPeriod)) {
                     validText = `${intl.formatMessage({
                        id: 'organisations.validFrom',
                        defaultMessage: 'valid from:',
                     })} ${parseISO(validFrom).toLocaleDateString()}`;
                  } else if ((noValidationDateValidFrom && isValidToInPeriod) || (noValidationDateValidFrom && notValidForPeriod)) {
                     validText = `${intl.formatMessage({
                        id: 'organisations.validTo',
                        defaultMessage: 'valid to:',
                     })} ${parseISO(validTo).toLocaleDateString()}`;
                  } else {
                     validText = `${parseISO(validFrom).toLocaleDateString()} - ${parseISO(validTo).toLocaleDateString()}`;
                  }

                  const formattedProportionPeriod = `${fromattedProportion} (${validText}) ${parentName}`;
                  return (
                     <Typography paddingBottom={1} fontSize={12} key={`${parent?.parentId}_${index}`}>
                        {formattedProportionPeriod}
                     </Typography>
                  );
               }
            })}
         </>
      );
   }
}

function capitalizeFirstLetter(string) {
   if (string) {
      const stringLC = string.toLowerCase();
      return stringLC.charAt(0).toUpperCase() + stringLC.slice(1);
   }
   return null;
}

function formatSelectedObject(selected, prop, value) {
   if (selected) {
      return {
         [prop]: value,
         value: selected.id,
         label: prop ? `${value} ${selected.name}` : selected.name,
      };
   }
   return null;
}

function taxonomySearchList(activities, mergedList) {
   let searchList = activities ? Array.from(activities) : [];
   if (mergedList?.length > 0) {
      mergedList.forEach((merged) => {
         searchList = activities?.filter((activity) => merged.id !== activity.id);
      });
   }
   // mergedList?.length > 0 && mergedList.forEach(merged => {
   // 	searchList = activities.filter(activity => merged.id !== activity.id)
   // })
   return searchList;
}

function groupBy(data = [], keyFn) {
   return data.reduce((all, current) => {
      const group = keyFn(current);
      const existingKey = all.find((existing) => existing.key === group);
      if (!existingKey) {
         all.push({ key: group, values: [current] });
      } else {
         existingKey.values.push(current);
      }
      return all;
   }, []);
}

function getContributionTypeColor(contributionTypeCode) {
   switch (contributionTypeCode) {
      case TaxonomyContributionType.SUBSTANTIAL_CONTRIBUTION:
      case TaxonomyContributionType.ENABLING_ACTIVITY:
      case TaxonomyContributionType.TRANSITIONAL_ACTIVITY:
      case TaxonomyContributionType.MINIMUM_SAFEGUARDS:
         return SUCCESS_COLOR;
      case TaxonomyContributionType.NOT_QUALIFIED:
         return 'error.main';
      case 'MIXED':
         return 'success.light';
      case TaxonomyContributionType.DO_NO_SIGNIFICANT_HARM:
      default:
         return 'grey[400]';
   }
}

function getAlignmentTypeColor(alignmentTypeCode) {
   switch (alignmentTypeCode) {
      case TaxonomyAlignmentType.ELIGIBLE:
         return 'info.main';
      case TaxonomyAlignmentType.ALIGNED:
         return SUCCESS_COLOR;
      case TaxonomyAlignmentType.PARTIALLY_ALIGNED:
         return 'info.main';
      case TaxonomyAlignmentType.NOT_ALIGNED:
         return 'error.main';
      case TaxonomyAlignmentType.NOT_ELIGIBLE:
      default:
         return 'grey[400]';
   }
}

function transformAllowedAuditorsAndEditorsToIds(object) {
   const allowedAuditors = (object?.allowedAuditors ?? []).map((auditor) => auditor.id) ?? [];
   const allowedEditors = (object?.allowedEditors ?? []).map((editor) => editor.id) ?? [];
   const allowedAuditorGroups = (object?.allowedAuditorGroups ?? []).flatMap((group) => group?.users.map((auditor) => auditor.id)) ?? [];
   const allowedEditorGroups = (object?.allowedEditorGroups ?? []).flatMap((group) => group?.users.map((editor) => editor.id)) ?? [];
   return {
      ...object,
      allowedAuditors: [...allowedAuditors, ...allowedAuditorGroups],
      allowedEditors: [...allowedEditors, ...allowedEditorGroups],
      allowedAuditorGroups,
      allowedEditorGroups,
   };
}

const warningMain = 'warning.main';

function getAuditStatusColor(auditStatus) {
   switch (auditStatus) {
      case ApprovalStatus.REVIEWED:
         return 'info.main';
      case ApprovalStatus.APPROVED:
         return SUCCESS_COLOR;
      case ApprovalStatus.ARCHIVED:
         return 'text.disabled';
      case 'UNARCHIVE':
         return warningMain;
      case 'UNAPPROVE':
         return warningMain;
      case 'UNREVIEW':
         return warningMain;
      case ApprovalStatus.NO_VERIFICATION_NEEDED:
      case ApprovalStatus.IN_PROGRESS:
      default:
         return 'text.primary';
   }
}

function getAuditStatusIcon(auditStatus) {
   switch (auditStatus) {
      case ApprovalStatus.REVIEWED:
         return 'check_circle_outline';
      case ApprovalStatus.APPROVED:
         return 'check_circle';
      case ApprovalStatus.ARCHIVED:
         return 'inventory_2';
      case 'UNARCHIVE':
         return 'unarchive';
      case 'UNAPPROVE':
         return 'thumbs-down';
      case 'UNREVIEW':
         return 'thumbs-down';
      case 'REVIEW':
         return 'eye';
      case 'APPROVE':
         return 'thumbs-up';
      case 'ARCHIVE':
         return 'box-archive';
      case ApprovalStatus.NO_VERIFICATION_NEEDED:
      case ApprovalStatus.IN_PROGRESS:
      default:
         return 'hardware';
   }
}

function getImportJobTypeColor(importJobType) {
   switch (importJobType) {
      case ImportJobTypes.SUCCESS:
         return SUCCESS_COLOR;
      case ImportJobTypes.ERROR:
         return 'error.main';
      case ImportJobTypes.WARNING:
         return warningMain;
      case ImportJobTypes.DELETED:
         return 'grey[700]';
      case ImportJobTypes.PENDING:
      default:
         return 'grey[400]';
   }
}

function getTaskStatusColor(taskStatus) {
   switch (taskStatus) {
      case 'OPEN':
         return 'error.main';
      case 'DELETED':
         return 'blueGrey[400]';
      case 'HOLD':
         return warningMain;
      case 'REVIEW':
         return 'orange[600]';
      case 'PROGRESS':
         return 'info.main';
      case 'FINISHED':
         return SUCCESS_COLOR;
      default:
         return 'grey[700]';
   }
}

function createMenuItems(objects = [], key = 'id', value = 'value', label = 'label') {
   return objects.map((option) => (
      <MenuItem key={option[key]} value={option[value]}>
         {option[label]}
      </MenuItem>
   ));
}

function replaceKeysInObj(data, oldKey, newKey) {
   const replaceKey = (func) => (newData) => {
      if (Array.isArray(newData)) {
         return newData.map(replaceKey(func));
      }

      if (Object(newData) === newData) {
         return Object.fromEntries(Object.entries(newData).map(([key, value]) => [func(key), replaceKey(func)(value)]));
      }
      return newData;
   };
   return replaceKey((key) => (key === oldKey ? newKey : key))(data);
}

function useCenteredTree(layout) {
   const [translate, setTranslate] = useState({ x: 0, y: 0 });
   const [minHeight, setMinHeight] = useState(150);
   const [containerWidth, setContainerWidth] = useState(0);
   const [containerHeight, setContainerHeight] = useState(0);
   const [treeWidth, setTreeWidth] = useState(360);
   const [fitZoomFactor, setFitZoomFactor] = useState(0);
   const [itemDim, setItemDim] = useState({ w: 0, h: 0 });
   const [zoomFactor, setZoomFactor] = useState(0);

   const updateZoomFactor = useCallback((newVal) => {
      setZoomFactor(newVal);
   }, []);

   const containerRef = useCallback(
      (containerElem) => {
         if (containerElem !== null) {
            const { height, width } = containerElem.getBoundingClientRect();
            const { height: tHeight, width: tWidth, left: tOffset } = containerElem.querySelector('.rd3t-g').getBoundingClientRect();
            const childNodes = containerElem.querySelector('.rd3t-g > .rd3t-node');

            let innerOffset;
            if (childNodes) {
               innerOffset = childNodes.getBoundingClientRect().left;
            } else {
               innerOffset = 0;
            }

            const treeWidth = tWidth;
            const treeHeight = tHeight;
            const zoomScale = Math.min(1 / ((treeWidth + 100) / width), 1);
            const offset = innerOffset * zoomScale - tOffset * zoomScale + 180 * zoomScale;
            const adjustedTreeWidth = treeWidth * zoomScale;
            const adjustedTreeHeight = treeHeight * zoomScale;
            setFitZoomFactor(zoomScale);
            setItemDim({ w: tWidth, h: tHeight });
            setContainerWidth(width);
            setContainerHeight(height);
            setMinHeight(adjustedTreeHeight + 100);
            setTreeWidth(adjustedTreeWidth);
            setTranslate({ x: (width - adjustedTreeWidth) / layout + offset, y: (height - adjustedTreeHeight) / layout });
            updateZoomFactor(zoomScale);
         }
      },
      [layout, updateZoomFactor]
   );
   return {
      translate,
      containerRef,
      minHeight,
      fitZoomFactor,
      containerWidth,
      containerHeight,
      treeWidth,
      itemDim,
      zoomFactor,
      updateZoomFactor,
   };
}

function convertFormulaArrayToExpression(formulaArray) {
   return formulaArray
      .map((item) => (typeof item === 'string' ? item.trim() : item)) // clean spaces
      .reduce((formulaText, currentToken, currentIndex, formulaArray_) => {
         const processedPart = formulaArray_.slice(0, currentIndex - 1);

         const lastClosedFormulaIndex = processedPart.lastIndexOf(')');
         const lastOpenedFormulaIndex = findLastIndex(
            processedPart,
            (item) => (item?.type === 'FUNCTION' && item?.value.match(/\w{1,30}\(/g)) || item.toString().match(/\w{1,30}\(/g)
         );

         if (currentIndex > 0 && lastClosedFormulaIndex < lastOpenedFormulaIndex && currentToken.toString() !== ')') {
            return formulaText.concat(',', currentToken);
         }

         return formulaText.concat(currentToken);
      }, '');
}

function replaceExpressions(expression) {
   return Array.from(expression, (formulaItem) => {
      if (typeof formulaItem === 'object') {
         if (formulaItem?.type === 'FUNCTION') {
            return formulaItem.value;
         }
         return 1;
      }
      return formulaItem;
   });
}

function getFormulaExpressionErrorMessage(expression) {
   if (!expression) {
      return undefined;
   }

   const expressions = Array.from(expression);

   const replacedExpressions = replaceExpressions(expressions);

   const formulaString = convertFormulaArrayToExpression(replacedExpressions);

   try {
      evaluate(formulaString);
      return undefined;
   } catch (e) {
      return e?.message;
   }
}

function taskCycle(intl) {
   return [
      {
         label: intl.formatMessage({
            id: 'tasks.cycle-once',
            defaultMessage: 'One time',
         }),
         value: TaskCycle.ONCE,
      },
      {
         label: intl.formatMessage({
            id: 'tasks.cycle-days',
            defaultMessage: 'Daily',
         }),
         value: TaskCycle.DAILY,
      },
      {
         label: intl.formatMessage({
            id: 'tasks.cycle-weeks',
            defaultMessage: 'Weekly',
         }),
         value: TaskCycle.WEEKLY,
      },
      {
         label: intl.formatMessage({
            id: 'tasks.cycle-months',
            defaultMessage: 'Monthly',
         }),
         value: TaskCycle.MONTHLY,
      },
      {
         label: intl.formatMessage({
            id: 'tasks.cycle-quarters',
            defaultMessage: 'Quarterly',
         }),
         value: TaskCycle.QUARTERLY,
      },
      {
         label: intl.formatMessage({
            id: 'tasks.cycle-years',
            defaultMessage: 'Yearly',
         }),
         value: TaskCycle.YEARLY,
      },
   ];
}

function taskPrio(intl) {
   return [
      {
         label: intl.formatMessage({
            id: 'tasks.priority1',
            defaultMessage: 'Highest',
         }),
         value: 1,
         color: 'error.main',
      },
      {
         label: intl.formatMessage({
            id: 'tasks.priority2',
            defaultMessage: 'High',
         }),
         value: 2,
         color: 'orange[600]',
      },
      {
         label: intl.formatMessage({
            id: 'tasks.priority3',
            defaultMessage: 'Medium',
         }),
         value: 3,
         color: 'warning.main',
      },
      {
         label: intl.formatMessage({
            id: 'tasks.priority4',
            defaultMessage: 'Low',
         }),
         value: 4,
         color: SUCCESS_COLOR,
      },
      {
         label: intl.formatMessage({
            id: 'tasks.priority5',
            defaultMessage: 'Lowest',
         }),
         value: 5,
         color: 'blueGrey[400]',
      },
   ];
}

function tasksAction(intl) {
   return new Map([
      [
         TaskStatus.OPEN,
         [
            {
               label: intl.formatMessage({
                  id: 'tasks.action.hold',
                  defaultMessage: 'Set On hold',
               }),
               value: TaskStatus.HOLD,
               color: TaskStatus.HOLD,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.finalize',
                  defaultMessage: 'Finalize',
               }),
               value: TaskStatus.REVIEW,
               color: TaskStatus.FINISHED,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.delete',
                  defaultMessage: 'Delete',
               }),
               value: TaskStatus.DELETED,
               color: TaskStatus.OPEN,
            },
         ],
      ],
      [
         TaskStatus.PROGRESS,
         [
            {
               label: intl.formatMessage({
                  id: 'tasks.action.hold',
                  defaultMessage: 'Set On hold',
               }),
               value: TaskStatus.HOLD,
               color: TaskStatus.HOLD,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.finalize',
                  defaultMessage: 'Finalize',
               }),
               value: TaskStatus.REVIEW,
               color: TaskStatus.FINISHED,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.delete',
                  defaultMessage: 'Delete',
               }),
               value: TaskStatus.DELETED,
               color: TaskStatus.OPEN,
            },
         ],
      ],
      [
         TaskStatus.REVIEW,
         [
            {
               label: intl.formatMessage({
                  id: 'tasks.action.reOpen',
                  defaultMessage: 'Re-Open',
               }),
               value: TaskStatus.OPEN,
               color: TaskStatus.PROGRESS,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.hold',
                  defaultMessage: 'Set On Hold',
               }),
               value: TaskStatus.HOLD,
               color: TaskStatus.HOLD,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.approve',
                  defaultMessage: 'Approve',
               }),
               value: TaskStatus.FINISHED,
               color: TaskStatus.FINISHED,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.delete',
                  defaultMessage: 'Delete',
               }),
               value: TaskStatus.DELETED,
               color: TaskStatus.OPEN,
            },
         ],
      ],
      [
         TaskStatus.HOLD,
         [
            {
               label: intl.formatMessage({
                  id: 'tasks.action.resume',
                  defaultMessage: 'Resume',
               }),
               value: TaskStatus.OPEN,
               color: TaskStatus.PROGRESS,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.delete',
                  defaultMessage: 'Delete',
               }),
               value: TaskStatus.DELETED,
               color: TaskStatus.OPEN,
            },
         ],
      ],
      [
         TaskStatus.FINISHED,
         [
            {
               label: intl.formatMessage({
                  id: 'tasks.action.reOpen',
                  defaultMessage: 'Re-Open',
               }),
               value: TaskStatus.OPEN,
               color: TaskStatus.PROGRESS,
            },
            {
               label: intl.formatMessage({
                  id: 'tasks.action.archive',
                  defaultMessage: 'Archive',
               }),
               value: TaskStatus.ARCHIVED,
               color: TaskStatus.DELETED,
            },
         ],
      ],
      [TaskStatus.DELETED, []],
      [TaskStatus.ARCHIVED, []],
   ]);
}

function determineDefaultEntryPath() {
   if (!ability) {
      return '403';
   }
   if (ability.can('read', 'chart') && ability.can('read', subject('overviewTab', { userId: -1 }))) {
      return 'dashboard/overview';
   }
   if (ability.can('read', 'kpiArea')) {
      return 'kpi';
   }
   if (ability.can('read', subject('businessActivity', {}))) {
      return 'taxonomy';
   }
   if (ability.can('read', subject('footprint', {}))) {
      return 'emissions/ghg';
   }
   if (ability.can('read', 'report')) {
      return 'reports';
   }
   if (ability.can('read', 'task')) {
      return 'tasks';
   }
   return 'users/me';
}

function determineDashboardEntryPath() {
   if (!ability) {
      return '403';
   }
   if (ability.can('read', 'chart') && ability.can('read', subject('overviewTab', { userId: -1 }))) {
      return 'dashboard/overview';
   }
   if (ability.can('read', 'kpiArea')) {
      return 'dashboard/overview';
   }
   if (ability.can('read', subject('businessActivity', {}))) {
      return 'taxonomy';
   }
   if (ability.can('read', subject('footprint', {}))) {
      return 'dashboard/status';
   } // TODO change if we have a dedicated dashboard
   return 'dashboard/status';
}

function nest(items, id = null, link = 'parentId') {
   return items.filter((item) => item[link] === id).map((item) => ({ ...item, children: nest(items, item.id) }));
}

function nestOrganisations(items, id = null) {
   return items
      .filter((item) => (id === null ? (item?.parents ?? []).length === 0 : (item?.parents ?? []).find((parent) => parent?.id === id)))
      .map((item) => ({ ...item, children: nestOrganisations(items, item.id) }));
}

function getHalfYear(date) {
   return getQuarter(date) <= 2 ? 1 : 2;
}

function friendlyFormatDateTimeRange(from, to) {
   const duration = intervalToDuration({ start: from, end: addDays(to, 1) });
   if (!duration) {
      return undefined;
   }

   const [unevenPart, unevenValue] = Object.entries(duration).find(([, value]) => value !== 0);

   if (unevenPart === 'years' && unevenValue === 1) {
      return format(to, 'yyyy');
   }

   if (!['years', 'months'].includes(unevenPart)) {
      return undefined;
   }

   if (Object.values(duration).filter((value) => value === 0).length !== Object.values(duration).length - 1) {
      return undefined;
   }

   if (unevenPart === 'months') {
      switch (unevenValue) {
         case 1:
            return format(to, 'MM yyyy');
         case 3:
            return isSameQuarter(from, to) ? format(to, 'QQQ yyyy') : undefined;
         case 6:
            return [0, 6].includes(getMonth(from)) ? `H${getHalfYear(from)} ${from.getFullYear()}` : undefined;
         default:
            return undefined;
      }
   }

   return undefined;
}

function fieldTypeRequiresUnit(kpiFieldType) {
   return [
      KPIFieldType.WEIGHT,
      KPIFieldType.SURFACE,
      KPIFieldType.MEASUREMENT,
      KPIFieldType.EMISSIONS,
      KPIFieldType.EMISSION_SELECT,
      KPIFieldType.DISTANCE,
      KPIFieldType.ELECTRICITY,
      KPIFieldType.VOLUME,
      KPIFieldType.WOOD,
      KPIFieldType.TIME,
      KPIFieldType.PERCENTAGE,
   ].includes(kpiFieldType);
}

function fieldTypeYTD(kpiFieldType) {
   return [
      KPIFieldType.WEIGHT,
      KPIFieldType.NUMBER,
      KPIFieldType.SURFACE,
      KPIFieldType.MEASUREMENT,
      KPIFieldType.EMISSIONS,
      KPIFieldType.DISTANCE,
      KPIFieldType.ELECTRICITY,
      KPIFieldType.VOLUME,
      KPIFieldType.WOOD,
      KPIFieldType.PERCENTAGE,
      KPIFieldType.PRICE,
   ].includes(kpiFieldType);
}

function fieldTypeAllowsUnit(kpiFieldType) {
   return fieldTypeRequiresUnit(kpiFieldType) || kpiFieldType === KPIFieldType.FORMULA;
}

function fieldTypeIsNumeric(kpiFieldType) {
   return [
      KPIFieldType.WEIGHT,
      KPIFieldType.SURFACE,
      KPIFieldType.PRICE,
      KPIFieldType.MEASUREMENT,
      KPIFieldType.EMISSIONS,
      KPIFieldType.EMISSION_SELECT,
      KPIFieldType.DISTANCE,
      KPIFieldType.ELECTRICITY,
      KPIFieldType.VOLUME,
      KPIFieldType.PERCENTAGE,
      KPIFieldType.NUMBER,
      KPIFieldType.DATA,
      KPIFieldType.FORMULA,
      KPIFieldType.PASSENGER,
      KPIFieldType.WOOD,
      KPIFieldType.TIME,
   ].includes(kpiFieldType);
}

function getValueTypeForFieldType(kpiFieldType) {
   switch (kpiFieldType) {
      case KPIFieldType.SELECT:
         return KPIValueType.INTEGER;
      case KPIFieldType.SWITCH:
         return KPIValueType.BOOLEAN;
      case KPIFieldType.WEIGHT:
      case KPIFieldType.SURFACE:
      case KPIFieldType.PRICE:
      case KPIFieldType.MEASUREMENT:
      case KPIFieldType.EMISSIONS:
      case KPIFieldType.EMISSION_SELECT:
      case KPIFieldType.DISTANCE:
      case KPIFieldType.ELECTRICITY:
      case KPIFieldType.VOLUME:
      case KPIFieldType.PERCENTAGE:
      case KPIFieldType.NUMBER:
      case KPIFieldType.DATA:
      case KPIFieldType.FORMULA:
      case KPIFieldType.PASSENGER:
      case KPIFieldType.WOOD:
      case KPIFieldType.TIME:
         return KPIValueType.DECIMAL;
      case KPIFieldType.DATE:
         return KPIValueType.DATE;
      case KPIFieldType.TEXT:
      case KPIFieldType.TEXTAREA:
      default:
         return KPIValueType.TEXT;
   }
}

function canAccessOrganisation(organisationId) {
   return ability.can('read', subject('organisation', { organisationId }));
}

function determineListStyle(criteria) {
   const listStyle = {};

   if (criteria.every((criterion) => !criterion.key)) {
      listStyle.listStyleType = 'none';
   }
   if (criteria.some((criterion) => criterion.key && Number.isInteger(Number.parseInt(criterion.key, 10)))) {
      listStyle.listStyleType = 'decimal';
   }
   if (criteria.every((criterion) => criterion.key && romanNumerals.includes(criterion.key))) {
      listStyle.listStyle = 'lower-roman-taxonomy';
   } else if (criteria.every((criterion) => criterion.key && /[a-z]/.test(criterion.key))) {
      listStyle.listStyle = 'lower-latin-taxonomy';
   }

   return listStyle;
}

function determineListIndex(key, { listStyle }) {
   if (!key) {
      return 0;
   }

   const normalizedKey = key.endsWith('.') ? key.substring(0, key.length - 1) : key;

   if (normalizedKey.includes('.') && Number.isInteger(Number.parseInt(normalizedKey, 10))) {
      return parseInt(normalizedKey.substring(normalizedKey.lastIndexOf('.') + 1), 10);
   }

   if (Number.isInteger(Number.parseInt(normalizedKey, 10))) {
      return parseInt(normalizedKey, 10);
   }

   if (romanNumerals.includes(normalizedKey) && listStyle === 'lower-roman-taxonomy') {
      return romanNumerals.indexOf(normalizedKey) + 1;
   }

   return normalizedKey.toLowerCase().charCodeAt(0) - 97 + 1;
}

function determineListItemStyle(key) {
   if (!key) {
      return 'none';
   }
   return 'inherit';
}

function getStatusColor(status) {
   switch (status) {
      case true:
      case 'APPROVED':
      case 'FINISHED':
      case 'CLOSED':
      case 'FILLED':
         return SUCCESS_COLOR;
      case false:
      case 'DELETED':
         return 'error.main';
      case 'ARCHIVED':
         return 'blueGrey[400]';
      case 'HOLD':
      case 'REVIEWED':
         return warningMain;
      case 'REVIEW':
         return 'orange[600]';
      case 'PROGRESS':
      case 'OPEN':
      case 'UNFILLED':
      case 'IN_PROGRESS':
      case 'NO_VERIFICATION_NEEDED':
         return 'info.main';
      default:
         return 'grey[400]';
   }
}

function getPercentageColor(value) {
   if (value <= 20) {
      return 'error.main';
   }
   if (value >= 21 && value <= 50) {
      return 'orange[600]';
   }
   if (value >= 51 && value <= 99) {
      return warningMain;
   }
   if (value >= 100) {
      return SUCCESS_COLOR;
   }
   return 'background.default';
}

function getActionStatusColor(status) {
   switch (status) {
      case 'LOW':
         return SUCCESS_COLOR;
      case 'MEDIUM':
      case 'MED':
         return warningMain;
      case 'HIGH':
         return 'orange[600]';
      case 'HIGHER':
         return 'error.main';
      default:
         return 'grey[700]';
   }
}

function getCurrencySymbol(intl, shortCode) {
   const parts = intl.formatNumberToParts(0, {
      style: 'currency',
      currency: shortCode,
      currencyDisplay: 'narrowSymbol',
      signDisplay: 'always',
   });
   return parts.find((part) => part.type === 'currency')?.value;
}

function convertDatesToUTC(obj) {
   return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, isValid(value) && isDate(value) ? formatISO(value, { representation: 'date' }) : value])
   );
}

const kpiGroupByString = (kpiSubSlug_, flatKPISet) => {
   const kpiSub = flatKPISet.find((kpiSub_) => kpiSub_.slug === kpiSubSlug_);
   return kpiSub?.key ? `${kpiSub?.key} ${kpiSub?.name}` : (kpiSub?.name ?? '');
};

const footprintGroupByString = (option) => {
   switch (option.kind) {
      case 'footprintsub':
         return option.footprint?.key ? `${option.footprint?.key} ${option.footprint?.name}` : (option.footprint?.name ?? '');
      case 'footprintcalculation':
         return (
            option.footprintSub.footprint?.key
               ? `${option.footprintSub.footprint?.key} ${option.footprintSub.footprint?.name}`
               : (option.footprintSub.footprint?.name ?? '')
         )
            .concat(' - ')
            .concat(option.footprintSub?.key ? `${option.footprintSub?.key} ${option.footprintSub?.name}` : (option.footprintSub?.name ?? ''));
      case 'footprintcalculationfield':
         return (
            option.fpCalculation.footprintSub.footprint?.key
               ? `${option.fpCalculation.footprintSub.footprint?.key} ${option.fpCalculation.footprintSub.footprint?.name}`
               : (option.fpCalculation.footprintSub.footprint?.name ?? '')
         )
            .concat(' - ')
            .concat(
               option.fpCalculation.footprintSub?.key
                  ? `${option.fpCalculation.footprintSub?.key} ${option.fpCalculation.footprintSub?.name}`
                  : (option.fpCalculation.footprintSub?.name ?? '')
            )
            .concat(' - ')
            .concat(option.fpCalculation?.key ? `${option.fpCalculation?.key} ${option.fpCalculation?.name}` : (option.fpCalculation?.name ?? ''));
      default:
         return '';
   }
};

function groupByKind(option, flatKPISet, intl) {
   if (option?.type === 'FUNCTION') {
      return intl.formatMessage({ id: 'formula.functions', defaultMessage: 'Functions' });
   }

   switch (option?.kind) {
      case 'emission':
         return intl.formatMessage({ id: 'formula.emissions', defaultMessage: 'Emissions' });
      case 'footprint':
         return intl.formatMessage({ id: 'entityKind.footprint', defaultMessage: 'Carbon Footprint' });
      case 'footprintsub':
      case 'footprintcalculation':
      case 'footprintcalculationfield':
         return footprintGroupByString(option);
      case 'kpifield':
         return kpiGroupByString(option.kpiContent?.kpiSub?.slug, flatKPISet);
      default:
         return undefined;
   }
}

function filterResultsForFormulaField(results, kind, kpiFieldId, intl, flatKPISet) {
   return results
      .filter(
         (result) =>
            (result?.kind === kind ? Number.parseInt(kpiFieldId, 10) !== result?.id : true) &&
            (result?.name || result?.label) &&
            (result.kind === 'kpifield' ? fieldTypeIsNumeric(result?.kpiFieldType?.name) : true)
      )
      .sort((a, b) => groupByKind(a, flatKPISet, intl).localeCompare(groupByKind(b, flatKPISet, intl)))
      .flatMap((result) =>
         result?.rows?.length > 0
            ? [
                 result,
                 ...result.rows.flatMap((row) => ({
                    ...pick(result, ['link', 'kpiContent', 'kind']),
                    ...result,
                    rowId: row.id,
                    row,
                    table: result?.group,
                    relativePeriods: [0],
                 })),
              ]
            : result
      );
}

function determineBaseUnit(unit) {
   if (!unit) {
      return unit;
   }

   const [, baseUnit_] = (unit ?? '').includes('/') ? unit.split('/') : [];

   let baseUnit;

   if ((baseUnit_ ?? '').includes('-')) {
      [, baseUnit] = baseUnit_.split('-');
   } else {
      baseUnit = baseUnit_;
   }

   if (baseUnit && Currencies.names.has(baseUnit)) {
      return undefined;
   }

   if (baseUnit in unitReplacement) {
      return baseUnit.replace(baseUnit, unitReplacement[baseUnit]);
   }
   return baseUnit;
}

function formatRegionCode(intl, code) {
   let formattedCode;

   let [sanitizedCode] = code.split('-');
   [sanitizedCode] = sanitizedCode.split('_');

   if (sanitizedCode === 'UK') {
      sanitizedCode = 'GB';
   }

   const regularIntl = new Intl.DisplayNames([intl.locale], { type: 'region', style: 'long' });

   try {
      regularIntl.of(sanitizedCode);

      formattedCode = intl.formatDisplayName(sanitizedCode, {
         type: 'region',
         style: 'long',
      });
   } catch {
      formattedCode = intl.formatMessage({
         id: `emissions.regions.${code}`,
         defaultMessage: regionCodeMapping?.[code] ?? code,
      });
   }

   return formattedCode;
}

function determineAuditStatusTooltipTitle(intl, object) {
   if (object?.status === ApprovalStatus.APPROVED && object?.approver && object?.reviewer) {
      return intl.formatMessage(
         {
            id: 'common.approvedBy',
            defaultMessage: 'Status: {status} {br} Approved by {approver} {br} Reviewed by {reviewer}',
         },
         {
            approver: `${object?.approver?.firstName} ${object?.approver?.lastName}`,
            reviewer: `${object?.reviewer?.firstName} ${object?.reviewer?.lastName}`,
            br: <br />,
            status: intl.formatMessage({
               id: `overview.tabs.status.${object?.status}`,
               defaultMessage: (object?.status ?? '').toLowerCase(),
            }),
         }
      );
   } else if (object?.status === ApprovalStatus.REVIEWED && object?.reviewer) {
      return intl.formatMessage(
         {
            id: 'common.reviewedBy',
            defaultMessage: 'Status: {status} {br} Reviewed by {reviewer}',
         },
         {
            reviewer: `${object?.reviewer?.firstName} ${object?.reviewer?.lastName}`,
            br: <br />,
            status: intl.formatMessage({
               id: `overview.tabs.status.${object?.status}`,
               defaultMessage: (object?.status ?? '').toLowerCase(),
            }),
         }
      );
   } else {
      return intl.formatMessage(
         {
            id: `overview.tabs.status.tooltip`,
            defaultMessage: 'Status: {status}',
         },
         {
            status: intl.formatMessage({
               id: `overview.tabs.status.${object?.status}`,
               defaultMessage: (object?.status ?? '').toLowerCase(),
            }),
         }
      );
   }
}

function getAuditStatusNode(intl, palette, object, fontSize = 20) {
   return (
      <Tooltip title={determineAuditStatusTooltipTitle(intl, object)}>
         <Box component="span" display="inline-flex">
            <Icon icon={getAuditStatusIcon(object?.status)} fontSize={fontSize} sx={{ color: get(palette, getAuditStatusColor(object?.status)) }} />
         </Box>
      </Tooltip>
   );
}

function getAuditActions(status) {
   switch (status) {
      case ApprovalStatus.IN_PROGRESS:
         return ['REVIEW', 'ARCHIVE'];
      case ApprovalStatus.REVIEWED:
         return ['APPROVE', 'UNREVIEW', 'ARCHIVE'];
      case ApprovalStatus.APPROVED:
         return ['UNAPPROVE', 'ARCHIVE'];
      case ApprovalStatus.ARCHIVED:
         return ['UNARCHIVE'];
      default:
         return [];
   }
}

function getAuditAllActions(entities) {
   if (entities.length < 1) {
      return [];
   }
   let status;
   const allInProgress = entities.every((entity) => entity.status === ApprovalStatus.IN_PROGRESS);
   const allReviewed = entities.every((entity) => entity.status === ApprovalStatus.REVIEWED);
   const allApproved = entities.every((entity) => entity.status === ApprovalStatus.APPROVED);
   const allArchived = entities.every((entity) => entity.status === ApprovalStatus.ARCHIVED);

   if (allInProgress) {
      status = ApprovalStatus.IN_PROGRESS;
   } else if (allReviewed) {
      status = ApprovalStatus.REVIEWED;
   } else if (allApproved) {
      status = ApprovalStatus.APPROVED;
   } else if (allArchived) {
      status = ApprovalStatus.ARCHIVED;
   } else {
      status = undefined;
   }
   return getAuditActions(status);
}

const replacementChars = ['/', '-'];

function sanitizeUnit(unit) {
   let returnUnit = unit ?? '';

   switch (returnUnit) {
      case 'ha':
         returnUnit = 'hectare';
         break;
      case 'a':
         returnUnit = 'acre';
         break;
      default:
   }

   returnUnit = returnUnit
      .replace(' CO₂', '')
      .replace('²', '^2')
      .replace('³', '^3')
      .replace(/\B(\d)/g, '^$1');

   replacementChars.forEach((char) => {
      if (returnUnit.includes(char)) {
         returnUnit = returnUnit
            .split(char)
            .map((aUnit) => {
               if (aUnit === 'ha') {
                  return 'hectare';
               }
               if (aUnit === 'a') {
                  return 'acre';
               }
               return aUnit;
            })
            .join(char);
      }
   });

   return returnUnit;
}

function convertUnit(unitFrom, unitTo, amount) {
   const sourceUnit = sanitizeUnit(unitFrom);
   const targetUnit = sanitizeUnit(unitTo);

   if (sourceUnit === targetUnit) {
      return amount;
   }

   if ((sourceUnit.endsWith(' CO₂') && !targetUnit.endsWith(' CO₂')) || (!sourceUnit.endsWith(' CO₂') && targetUnit.endsWith(' CO₂'))) {
      throw new Error(JSON.stringify({ error: 'units_not_compatible', from: unitFrom ?? '', to: unitTo ?? '', amount }));
   }

   if ([sourceUnit, targetUnit].some((aUnit) => ['Gt', 'Mt'].includes(aUnit))) {
      return unit(`${amount}${sourceUnit}`).toNumber(targetUnit);
   }

   if ((!sourceUnit && targetUnit) || (targetUnit && !sourceUnit)) {
      throw new Error(JSON.stringify({ error: 'units_not_compatible', from: unitFrom ?? '', to: unitTo ?? '', amount }));
   }

   if (!Qty.parse(sourceUnit)) {
      throw new Error(JSON.stringify({ error: 'unknown_unit', unit: unitFrom, amount }));
   }
   if (!Qty.parse(targetUnit)) {
      throw new Error(JSON.stringify({ error: 'unknown_unit', unit: targetUnit, amount }));
   }
   if (Qty.parse(sourceUnit) && Qty.parse(targetUnit) && !Qty.parse(sourceUnit).isCompatible(Qty.parse(targetUnit))) {
      throw new Error(JSON.stringify({ error: 'units_not_compatible', from: unitFrom ?? '', to: unitTo ?? '', amount }));
   }

   return Qty.parse(`${amount}${sourceUnit}`).to(targetUnit).scalar;
}

function formatEmissionResult(intl, result, emissionUnit, formatOptions = {}) {
   return `${intl.formatNumber(convertUnit('kg', emissionUnit, result), {
      useGrouping: true,
      maximumFractionDigits: 0,
      style: sanctionedUnits.some((unit) => Qty.getAliases(emissionUnit).includes(unit)) ? 'unit' : 'decimal',
      unit: sanctionedUnits.find((unit) => Qty.getAliases(emissionUnit).includes(unit)),
      ...formatOptions,
   })} ${sanctionedUnits.some((unit) => Qty.getAliases(emissionUnit).includes(unit)) ? ' ' : emissionUnit.concat(' ')}CO₂`;
}

function generateLinkForKind(kind = '', context = {}) {
   switch (kind.toLowerCase()) {
      case SearchResultKind.KPI_AREA:
         return context?.slug ? `/kpi/${context?.slug}` : undefined;
      case SearchResultKind.KPI:
         return context?.kpiArea?.slug && context?.slug ? `/kpi/${context?.kpiArea?.slug}/${context?.slug}` : undefined;
      case SearchResultKind.KPI_SUB:
         return context?.kpi?.kpiArea?.slug && context.kpi?.slug && context?.slug
            ? `/kpi/${context?.kpi?.kpiArea?.slug}/${context.kpi?.slug}/${context?.slug}`
            : undefined;
      case SearchResultKind.KPI_CONTENT:
         return context?.kpiSub?.kpi?.kpiArea?.slug && context.kpiSub?.kpi?.slug && context.kpiSub?.slug && context?.slug
            ? `/kpi/${context?.kpiSub?.kpi?.kpiArea?.slug}/${context.kpiSub?.kpi?.slug}/${context.kpiSub?.slug}/${context?.slug}`
            : undefined;
      case SearchResultKind.KPI_FIELD:
         return `/kpi/${context?.kpiContent?.kpiSub?.kpi?.kpiArea?.slug}/${context?.kpiContent?.kpiSub?.kpi?.slug}/${context?.kpiContent?.kpiSub?.slug}/${context?.kpiContent?.slug}/${context?.slug}`;
      case SearchResultKind.BUSINESS_ACTIVITY:
         return context?.id ? `/taxonomy/businessactivities/${context?.id}` : undefined;
      case SearchResultKind.FOOTPRINT:
         return context?.id ? `/emissions/ghg/footprints/${context?.id}` : undefined;
      case SearchResultKind.FOOTPRINT_SUB:
         return context?.footprint?.id && context?.slug ? `/emissions/ghg/footprints/${context?.footprint?.id}/${context?.slug}` : undefined;
      case SearchResultKind.REPORT:
         return context?.id ? `/reports/documents/${context?.id}` : undefined;
      case SearchResultKind.FOOTPRINT_CALCULATION:
      case SearchResultKind.FP_CALCULATION:
         return context?.footprintSub?.id && context?.slug
            ? `/emissions/ghg/footprints/${context?.footprintSub?.footprint?.id}/${context?.footprintSub?.slug}/${context?.slug}`
            : undefined;
      case SearchResultKind.FOOTPRINT_CALCULATION_FIELD:
      case SearchResultKind.FP_CALCULATION_FIELD:
         return context?.fpCalculation?.id && context?.slug
            ? `/emissions/ghg/footprints/${context?.fpCalculation?.footprintSub?.footprint?.id}/${context?.fpCalculation?.footprintSub?.slug}/${context?.fpCalculation?.id}/${context?.slug}`
            : undefined;
      default:
         return undefined;
   }
}

function useReferencedObjectNavigation(navigate) {
   const navigateToRef = (referencedObject) => {
      const value = referencedObject;
      if (value?.kind) {
         const link = generateLinkForKind(value.kind, value);

         if (link) {
            return navigate(link);
         }
         return undefined;
      }
   };

   return {
      navigateToRef,
   };
}

function mergeFormatOptions(specificFormatOptions, defaultFormatOptions) {
   const attributesToSkip = [];

   if (
      specificFormatOptions?.minimumFractionDigits !== defaultFormatOptions?.minimumFractionDigits ||
      specificFormatOptions?.maximumFractionDigits !== defaultFormatOptions?.maximumFractionDigits
   ) {
      attributesToSkip.push('minimumSignificantDigits');
      attributesToSkip.push('maximumSignificantDigits');
   }

   if (
      specificFormatOptions?.minimumSignificantDigits !== defaultFormatOptions?.minimumSignificantDigits ||
      specificFormatOptions?.maximumSignificantDigits !== defaultFormatOptions?.maximumSignificantDigits
   ) {
      attributesToSkip.push('minimumFractionDigits');
      attributesToSkip.push('maximumFractionDigits');
   }

   return merge(omit(defaultFormatOptions, attributesToSkip), specificFormatOptions);
}

export {
   canAccessOrganisation,
   capitalizeFirstLetter,
   checkOverlappingPeriod,
   checkProportionPerParent,
   convertDatesToUTC,
   convertFormulaArrayToExpression,
   convertUnit,
   createMenuItems,
   determineAuditStatusTooltipTitle,
   determineBaseUnit,
   determineDashboardEntryPath,
   determineDefaultEntryPath,
   determineListIndex,
   determineListItemStyle,
   determineListStyle,
   fieldTypeAllowsUnit,
   fieldTypeIsNumeric,
   fieldTypeRequiresUnit,
   fieldTypeYTD,
   filterResultsForFormulaField,
   formatEmissionResult,
   formatOrganisationName,
   formatRegionCode,
   formatSelectedObject,
   friendlyFormatDateTimeRange,
   generateLinkForKind,
   getActionStatusColor,
   getAlignmentTypeColor,
   getAuditActions,
   getAuditAllActions,
   getAuditStatusColor,
   getAuditStatusIcon,
   getAuditStatusNode,
   getContributionTypeColor,
   getCorrectOrganisationParent,
   getCurrencySymbol,
   getDecimalSeparator,
   getEntityKindIcon,
   getEntityRelationIcon,
   getFormulaExpressionErrorMessage,
   getGroupingSeparator,
   getImportJobTypeColor,
   getOrganisationIcon,
   getOrganisationIconKey,
   getPercentageColor,
   getReferencedAreaIcon,
   getStatusColor,
   getTaskStatusColor,
   getValueTypeForFieldType,
   groupArrayByKey,
   groupBy,
   groupByKind,
   handleDrag,
   localizeLanguageOptions,
   localizeOptions,
   mergeFormatOptions,
   nest,
   nestOrganisations,
   replaceKeysInObj,
   taskCycle,
   taskPrio,
   tasksAction,
   taxonomyReplaceFunction,
   taxonomySearchList,
   transformAllowedAuditorsAndEditorsToIds,
   truncateNumber,
   useCenteredTree,
   useReferencedObjectNavigation
};

