import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Icon as MuiIcon, SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as BioLeavesIcon } from 'resources/icons/bio-leaves.svg';
import { ReactComponent as BusinessmanIcon } from 'resources/icons/businessman.svg';
import { ReactComponent as CO2Icon } from 'resources/icons/co2.svg';
import { ReactComponent as CorruptionIcon } from 'resources/icons/corruption.svg';
import { ReactComponent as CrowdUsersIcon } from 'resources/icons/crowd-of-users.svg';
import { ReactComponent as CubeSquaresIcon } from 'resources/icons/cube-graphic-of-squares.svg';
import { ReactComponent as CurvedArrowIcon } from 'resources/icons/curved-arrow.svg';
import { ReactComponent as DataPrivacyIcon } from 'resources/icons/data-privacy.svg';
import { ReactComponent as DraftIcon } from 'resources/icons/draft.svg';
import { ReactComponent as EconomyBookIcon } from 'resources/icons/economy-book.svg';
import { ReactComponent as EnergyLeafIcon } from 'resources/icons/energy-leaf.svg';
import { ReactComponent as EnvoriaIcon } from 'resources/icons/envoria-icon.svg';
import { ReactComponent as EnvoriaLogoDark } from 'resources/icons/envoria-logo-dark.svg';
import { ReactComponent as EnvoriaLogo } from 'resources/icons/envoria-logo.svg';
import { ReactComponent as EqualityIcon } from 'resources/icons/equality.svg';
import { ReactComponent as EUFlagIcon } from 'resources/icons/eu-union.svg';
import { ReactComponent as FisaIcon } from 'resources/icons/fisa.svg';
import { ReactComponent as FreedomIcon } from 'resources/icons/freedom.svg';
import { ReactComponent as GoogleIcon } from 'resources/icons/google.svg';
import { ReactComponent as HealthIcon } from 'resources/icons/health.svg';
import { ReactComponent as HierarcyIcon } from 'resources/icons/hierarcy.svg';
import { ReactComponent as HoldingHandsIcon } from 'resources/icons/holding-hands.svg';
import { ReactComponent as LawBookIcon } from 'resources/icons/law-book.svg';
import { ReactComponent as ManInOfficeIcon } from 'resources/icons/man-in-office.svg';
import { ReactComponent as MicrosoftIcon } from 'resources/icons/microsoft.svg';
import { ReactComponent as OpenIDIcon } from 'resources/icons/openid.svg';
import { ReactComponent as PagelinesIcon } from 'resources/icons/pagelines.svg';
import { ReactComponent as PeoplePointerIcon } from 'resources/icons/people-pointer.svg';
import { ReactComponent as PerformanceIcon } from 'resources/icons/performance.svg';
import { ReactComponent as PickShovelIcon } from 'resources/icons/pick-shovel.svg';
import { ReactComponent as PlanetEarthIcon } from 'resources/icons/planet-earth.svg';
import { ReactComponent as PlantHandIcon } from 'resources/icons/plant-on-a-hand.svg';
import { ReactComponent as PublicSpeechIcon } from 'resources/icons/public-speech.svg';
import { ReactComponent as SisterBrotherIcon } from 'resources/icons/sister-brother.svg';
import { ReactComponent as SlackIcon } from 'resources/icons/slack.svg';
import { ReactComponent as StatsIcon } from 'resources/icons/stats.svg';
import { ReactComponent as TubeChemistryIcon } from 'resources/icons/tube-chemistry.svg';
import { ReactComponent as WasteIcon } from 'resources/icons/waste.svg';

import { ReactComponent as BusinessCentralIcon } from 'resources/icons/DataSourceTypes/business-central-logo.svg';
import { ReactComponent as CsvIcon } from 'resources/icons/DataSourceTypes/csv.svg';
import { ReactComponent as DatabaseBWIcon } from 'resources/icons/DataSourceTypes/database-black-white.svg';
import { ReactComponent as ExcelIcon } from 'resources/icons/DataSourceTypes/excel.svg';
import { ReactComponent as JsonBWIcon } from 'resources/icons/DataSourceTypes/json-black-white.svg';
import { ReactComponent as JsonIcon } from 'resources/icons/DataSourceTypes/json.svg';
import { ReactComponent as MariaDBIcon } from 'resources/icons/DataSourceTypes/mariadb-logo.svg';
import { ReactComponent as MicrosoftSQLServerIcon } from 'resources/icons/DataSourceTypes/mssql-server-logo.svg';
import { ReactComponent as MySqlIcon } from 'resources/icons/DataSourceTypes/mysql-logo.svg';
import { ReactComponent as OracleIcon } from 'resources/icons/DataSourceTypes/oracle-logo.svg';
import { ReactComponent as PdfBWIcon } from 'resources/icons/DataSourceTypes/pdf-black-white.svg';
import { ReactComponent as PdfIcon } from 'resources/icons/DataSourceTypes/pdf.svg';
import { ReactComponent as PostgreSqlIcon } from 'resources/icons/DataSourceTypes/postgresql-logo.svg';
import { ReactComponent as SapIcon } from 'resources/icons/DataSourceTypes/sap.svg';
import { ReactComponent as TextFileBWIcon } from 'resources/icons/DataSourceTypes/txt-black-white.svg';
import { ReactComponent as XmlIcon } from 'resources/icons/DataSourceTypes/xml.svg';
import { ReactComponent as OktaIcon } from 'resources/icons/okta.svg';
import { ReactComponent as PassKeyIcon } from 'resources/icons/passkey.svg';
import { ReactComponent as SAMLIcon } from 'resources/icons/saml.svg';
import { ReactComponent as WebAuthnIcon } from 'resources/icons/webauthn.svg';

const Icon = function ({ library = null, icon = null, fontSize = null, style = {}, ...props }) {
   let fontSizeAsNumber;
   if (['small', 'medium', 'large'].includes(fontSize)) {
      switch (fontSize) {
         case 'small':
            fontSizeAsNumber = 20;
            break;
         case 'large':
            fontSizeAsNumber = 32;
            break;
         case 'medium':
         default:
            fontSizeAsNumber = 24;
            break;
      }
   }

   function getIcon() {
      const iconDef = findIconDefinition({ iconName: icon });
      let FontAwIcon;

      if (iconDef) {
         FontAwIcon = (
            <FontAwesomeIcon style={fontSizeAsNumber ? { fontSize: fontSizeAsNumber, ...style } : { fontSize, ...style }} icon={iconDef} {...props} />
         );
      }
      const MaterialUiIcon = (
         <MuiIcon style={fontSizeAsNumber ? { fontSize: fontSizeAsNumber, ...style } : { fontSize, ...style }} {...props}>
            {icon}
         </MuiIcon>
      );

      return FontAwIcon ?? MaterialUiIcon;
   }

   const condition = fontSizeAsNumber
      ? { width: fontSizeAsNumber, height: fontSizeAsNumber, ...style }
      : { width: fontSize, height: fontSize, ...style };

   if (!library) {
      // TODO move this to a mapping file, instead of inline code
      // eslint-disable-next-line sonarjs/max-switch-cases
      switch (icon) {
         case 'bio-leaves':
            return <SvgIcon component={BioLeavesIcon} style={condition} viewBox="0 0 493 493" {...props} />;
         case 'businessman':
            return <SvgIcon component={BusinessmanIcon} style={condition} viewBox="0 0 486.912 486.912" {...props} />;
         case 'co2':
            return <SvgIcon component={CO2Icon} style={condition} viewBox="0 0 439 439" {...props} />;
         case 'corruption':
            return <SvgIcon component={CorruptionIcon} style={condition} viewBox="0 0 496 496" {...props} />;
         case 'crowd-users':
            return <SvgIcon component={CrowdUsersIcon} style={condition} viewBox="0 0 97.968 97.968" {...props} />;
         case 'cube-squares':
            return <SvgIcon component={CubeSquaresIcon} style={condition} viewBox="0 0 100.642 100.642" {...props} />;
         case 'curved-arrow':
            return <SvgIcon component={CurvedArrowIcon} style={condition} viewBox="0 0 155.74 92.25" {...props} />;
         case 'data-privacy':
            return <SvgIcon component={DataPrivacyIcon} style={condition} viewBox="0 0 32 32" {...props} />;
         case 'draft':
            return <SvgIcon component={DraftIcon} style={condition} viewBox="0 0 31.854 31.854" {...props} />;
         case 'economy-book':
            return <SvgIcon component={EconomyBookIcon} style={condition} viewBox="0 0 31.465 31.465" {...props} />;
         case 'energy-leaf':
            return <SvgIcon component={EnergyLeafIcon} style={condition} viewBox="0 -28 512.00075 512" {...props} />;
         case 'envoria-logo':
         case 'envoria-logo-light':
            return <SvgIcon component={EnvoriaLogo} viewBox="0 0 708.681 159.602" style={{ width: '100%', height: 50 }} {...props} />;
         case 'envoria-logo-dark':
            return <SvgIcon component={EnvoriaLogoDark} viewBox="0 0 708.681 159.602" style={{ width: '100%', height: 50 }} {...props} />;
         case 'envoria-icon':
            return <SvgIcon component={EnvoriaIcon} style={condition} viewBox="158 157 172 159.8" {...props} />;
         case 'equality':
            return <SvgIcon component={EqualityIcon} style={condition} viewBox="0 0 512 512" {...props} />;
         case 'eu-flag':
            return <SvgIcon component={EUFlagIcon} style={condition} viewBox="2.013 2 233.492 231.957" {...props} />;
         case 'fisa':
            return <SvgIcon component={FisaIcon} style={condition} viewBox="0 0 258.71 228.44" {...props} />;
         case 'freedom':
            return <SvgIcon component={FreedomIcon} style={condition} viewBox="0 0 470 470" {...props} />;
         case 'health':
            return <SvgIcon component={HealthIcon} style={condition} viewBox="0 0 295.531 295.531" {...props} />;
         case 'hierarcy':
            return <SvgIcon component={HierarcyIcon} style={condition} viewBox="0 0 488.416 488.416" {...props} />;
         case 'holding-hands':
            return <SvgIcon component={HoldingHandsIcon} style={condition} viewBox="0 0 45.22 45.22" {...props} />;
         case 'law-book':
            return <SvgIcon component={LawBookIcon} style={condition} viewBox="0 0 463 463" {...props} />;
         case 'man-in-office':
            return <SvgIcon component={ManInOfficeIcon} style={condition} viewBox="0 0 56.787 56.787" {...props} />;
         case 'pagelines':
            return <SvgIcon component={PagelinesIcon} style={condition} viewBox="0 0 384 512" {...props} />;
         case 'people-pointer':
            return <SvgIcon component={PeoplePointerIcon} style={condition} viewBox="0 0 430 430" {...props} />;
         case 'performance':
            return <SvgIcon component={PerformanceIcon} style={condition} viewBox="0 0 96.343 96.343" {...props} />;
         case 'pick-shovel':
            return <SvgIcon component={PickShovelIcon} style={condition} viewBox="0 0 470 470" {...props} />;
         case 'planet-earth':
            return <SvgIcon component={PlanetEarthIcon} style={condition} viewBox="0 0 512 512" {...props} />;
         case 'plant-hands':
            return <SvgIcon component={PlantHandIcon} style={condition} viewBox="0 0 114.01 114.01" {...props} />;
         case 'public-speech':
            return <SvgIcon component={PublicSpeechIcon} style={condition} viewBox="0 0 447 447" {...props} />;
         case 'sister-brother':
            return <SvgIcon component={SisterBrotherIcon} style={condition} viewBox="0 0 457.254 457.254" {...props} />;
         case 'stats':
            return <SvgIcon component={StatsIcon} style={condition} viewBox="0 0 487.343 487.343" {...props} />;
         case 'tube-chemistry':
            return <SvgIcon component={TubeChemistryIcon} style={condition} viewBox="0 0 31.93 31.93" {...props} />;
         case 'waste':
            return <SvgIcon component={WasteIcon} style={condition} viewBox="0 0 208.333 208.333" {...props} />;
         case 'excel':
            return <SvgIcon component={ExcelIcon} style={condition} viewBox="4.6 6 39 36" {...props} />;
         case 'database-bw':
            return <SvgIcon component={DatabaseBWIcon} style={condition} viewBox="2.5 0 50 55" {...props} />;
         case 'txt-file-bw':
            return <SvgIcon component={TextFileBWIcon} style={condition} viewBox="6.5 0 45 58" {...props} />;
         case 'pdf-file-bw':
            return <SvgIcon component={PdfBWIcon} style={condition} viewBox="6.5 0 45 58" {...props} />;
         case 'json-file-bw':
            return <SvgIcon component={JsonBWIcon} style={condition} viewBox="6.5 0 45 58" {...props} />;
         case 'csv-file':
            return <SvgIcon component={CsvIcon} style={condition} viewBox="6.5 0 45 58" {...props} />;
         case 'json-file':
            return <SvgIcon component={JsonIcon} style={condition} viewBox="3.95 3.95 24.1 24.1" {...props} />;
         case 'mysql':
            return <SvgIcon component={MySqlIcon} style={condition} viewBox="11.5 8.5 127 135" {...props} />;
         case 'mariadb':
            return <SvgIcon component={MariaDBIcon} style={condition} viewBox="0 0 309.88 252.72" {...props} />;
         case 'pdf-file':
            return <SvgIcon component={PdfIcon} style={condition} viewBox="6.5 0 43 56" {...props} />;
         case 'postgresql':
            return <SvgIcon component={PostgreSqlIcon} style={condition} viewBox="-4 0 264 264" {...props} />;
         case 'sap':
            return <SvgIcon component={SapIcon} style={condition} viewBox="0 0 48 48" {...props} />;
         case 'xml-file':
            return <SvgIcon component={XmlIcon} style={condition} viewBox="6.5 0 43 56" {...props} />;
         case 'business-central':
            return <SvgIcon component={BusinessCentralIcon} style={condition} viewBox="0 0 96 96" {...props} />;
         case 'oracle':
            return <SvgIcon component={OracleIcon} style={condition} viewBox="0 0 231 30" {...props} />;
         case 'mssql':
            return <SvgIcon component={MicrosoftSQLServerIcon} style={condition} viewBox="65.1 102.4 367.4 295.19999999999993" {...props} />;
         case 'azure':
         case 'microsoft':
            return <SvgIcon component={MicrosoftIcon} style={condition} viewBox="0 0 21 21" {...props} />;
         case 'slack':
            return <SvgIcon component={SlackIcon} style={condition} viewBox="0 0 122.8 122.8" {...props} />;
         case 'google':
            return <SvgIcon component={GoogleIcon} style={condition} viewBox="0 0 46 46" {...props} />;
         case 'github':
            return (
               <FontAwesomeIcon
                  icon={['fab', 'github']}
                  style={fontSizeAsNumber ? { fontSize: fontSizeAsNumber, ...style } : { fontSize, ...style }}
                  {...props}
               />
            );
         case 'openid':
            return <SvgIcon component={OpenIDIcon} style={condition} viewBox="0 0 2415 8334" {...props} />;
         case 'okta':
            return <SvgIcon component={OktaIcon} style={condition} viewBox="0 0 63 63" {...props} />;
         case 'webauthn':
            return <SvgIcon component={WebAuthnIcon} style={condition} viewBox="0 0 140 38" {...props} />;
         case 'passkey':
            return <SvgIcon component={PassKeyIcon} style={condition} viewBox="0 0 24 24" {...props} />;
         case 'saml':
            return <SvgIcon component={SAMLIcon} style={condition} viewBox="0 0 16 16" {...props} />;
         default:
            return getIcon();
      }
   } else if (library === 'mui') {
      return (
         <MuiIcon
            style={fontSizeAsNumber ? { fontSize: fontSizeAsNumber, ...style } : { fontSize: parseInt(fontSize ?? '24', 10), ...style }}
            {...props}
         >
            {icon}
         </MuiIcon>
      );
   } else {
      const iconDef = findIconDefinition({ prefix: library, iconName: icon });

      if (iconDef) {
         return (
            <FontAwesomeIcon style={fontSizeAsNumber ? { fontSize: fontSizeAsNumber, ...style } : { fontSize, ...style }} icon={iconDef} {...props} />
         );
      }
      return <Box />;
   }
};

Icon.propTypes = {
   library: PropTypes.string,
   icon: PropTypes.string,
   fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
   style: PropTypes.shape({}),
};

export default Icon;
